<header
    class="m-header"
    #nav
>
    <div class="m-header-navbar">
        <div class="m-header-navbar-left">
            <a
                class="m-org"
                id="navHomeAnchor"
                [routerLink]="['/']"
            >
                <img
                    class="m-org__logo"
                    [attr.src]="organization?.logoUrl ? organization.logoUrl : '/assets/img/brand-icon.png'"
                    alt="logo"
                />

                <div
                    class="m-org__info"
                    *ngIf="isDesktop"
                >
                    <span
                        class="m-org__name"
                        *ngIf="isAuthenticated !== null && isAuthenticated === true"
                    >
                        {{ organization?.name }}</span
                    >
                    <span class="m-org__product">{{
                        "poweredBy" | translate: { productName: websiteService.getProductName() }
                    }}</span>
                </div>
            </a>
        </div>

        <div class="m-header-navbar-right">
            <nav
                class="m-nav-primary"
                #navbar
            >
                <ul class="m-nav-list">
                    <!-- currentUser means we're authenticated, user means we know who we are.
                        After login we know we're authenticated, but don't know yet who we are for a little while -->
                    <ng-container *ngIf="isAuthorized">
                        <ng-container *ngIf="isAuthorized">
                            <ng-template
                                [ngTemplateOutlet]="loggedInTemplate"
                                [ngTemplateOutletContext]="{ $implicit: availableNavLinks }"
                            >
                            </ng-template>
                            <ng-template
                                *ngIf="isMobile"
                                [ngTemplateOutlet]="logoutDropdown"
                                [ngTemplateOutletContext]="{ cssClass: 'dropdown-mobile-only' }"
                            ></ng-template>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="isAuthenticated !== null && isAuthenticated === false">
                        <ng-template [ngTemplateOutlet]="notLoggedInTemplate"></ng-template>
                    </ng-container>

                    <ng-template
                        #loggedInTemplate
                        let-navLinks
                    >
                        <ng-container *ngFor="let link of navLinks">
                            <li class="m-nav-list-item">
                                <a
                                    class="m-nav-link"
                                    [id]="'nav' + link.screenName + 'MenuAnchor'"
                                    [ngClass]="{ 'is-active': isActive(link) }"
                                    [routerLink]="[link.route]"
                                    (click)="closeMenus()"
                                >
                                    <app-svg-icon [icon]="link.icon"> </app-svg-icon>
                                    <span> {{ link.title | translate }}</span>
                                </a>
                            </li>
                        </ng-container>
                    </ng-template>

                    <ng-template #notLoggedInTemplate>
                        <li
                            class="m-nav-list-item"
                            [routerLinkActive]="['link-active']"
                        >
                            <a
                                class="m-nav-link"
                                id="navMenuAboutAnchor"
                                [routerLink]="['/about']"
                                (click)="closeMenus()"
                            >
                                <app-svg-icon icon="info"></app-svg-icon>
                                {{ "about.title" | translate: { productName: websiteService.getProductName() } }}
                            </a>
                        </li>

                        <li class="m-nav-list-item">
                            <a
                                class="m-nav-link"
                                id="navMenuLogin"
                                (click)="closeMenus(); login()"
                            >
                                <app-svg-icon icon="profile"></app-svg-icon>
                                {{ "general.login" | translate }}
                            </a>
                        </li>
                    </ng-template>
                </ul>
            </nav>

            <ng-template
                #logoutDropdown
                let-cssClass="cssClass"
            >
                <div
                    class="nav-dropdown m-dropdown m-account-dropdown"
                    id="navMenuDropdown"
                    #navdropdown
                    [ngClass]="cssClass"
                >
                    <a
                        class="m-dropdown-toggle"
                        (click)="toggleDropdown()"
                    >
                        <!-- <app-svg-icon icon="profile">
                            </app-svg-icon> -->
                        <span class="m-account-item has-background">
                            {{ authorizationInfo && authorizationInfo.user.firstName.charAt(0) | uppercase
                            }}{{ authorizationInfo && authorizationInfo.user.lastName.charAt(0) | uppercase }}
                        </span>
                    </a>

                    <ul class="m-dropdown-menu">
                        <li
                            *ngIf="!isDesktop"
                            style="color: white"
                        >
                            <h3 style="color: white; padding: 10px">
                                {{ authorizationInfo && authorizationInfo.user.email }}
                            </h3>
                        </li>
                        <li *ngIf="showImpersonationDropdown">
                            <p-dropdown
                                id="navMenuImpersonateOrganization"
                                #impersonateOrganizationDropdown
                                [autoDisplayFirst]="false"
                                [filter]="true"
                                [ngModel]="impersonationService.organization"
                                [options]="impersonationOrganizations"
                                [resetFilterOnHide]="true"
                                [showClear]="true"
                                [style]="{ width: '100%' }"
                                (onChange)="setImpersonation($event.value)"
                                appendTo="body"
                                dataKey="id"
                                placeholder="{{ 'admin.impersonate' | translate }}"
                                styleClass="impersonate-dropdown"
                            >
                            </p-dropdown>
                        </li>
                        <li *ngIf="showRoleImpersonationDropdown">
                            <p-dropdown
                                id="navMenuImpersonateRole"
                                #impersonateRoleDropdown
                                [autoDisplayFirst]="false"
                                [filter]="true"
                                [ngModel]="impersonationService.role"
                                [options]="roles"
                                [resetFilterOnHide]="true"
                                [showClear]="true"
                                [style]="{ width: '100%' }"
                                (onChange)="setRoleImpersonation($event.value)"
                                appendTo="body"
                                placeholder="{{ 'admin.impersonateRole' | translate }}"
                            ></p-dropdown>
                        </li>
                        <li *ngIf="showMockProgress">
                            <a
                                id="navMenuMockProgress"
                                (click)="mockProgress()"
                                >Mock progress</a
                            >
                        </li>
                        <li>
                            <a
                                id="navMenuAboutAnchor"
                                [routerLink]="['/about']"
                                [routerLinkActive]="['link-active']"
                                (click)="closeMenus()"
                            >
                                {{ "about.title" | translate: { productName: websiteService.getProductName() } }}
                            </a>
                        </li>
                        <li class="language-picker">
                            <ng-template
                                #languageTemplate
                                let-language
                            >
                                <div class="flex align-center">
                                    <app-svg-icon
                                        [icon]="language"
                                        file="assets/flag-icons.svg"
                                        fill="black"
                                        style="width: 32px"
                                    />
                                    {{ language | translateEnum: "language" }}
                                </div>
                            </ng-template>

                            <p-dropdown
                                id="navMenuLanguage"
                                #languageDropdown
                                *ngIf="languages"
                                [autoDisplayFirst]="false"
                                [ngModel]="currentLanguage"
                                [options]="languages"
                                [style]="{ width: '100%' }"
                                (ngModelChange)="setLanguage($event)"
                                appendTo="body"
                            >
                                <ng-template
                                    let-language
                                    pTemplate="selectedItem"
                                >
                                    <ng-template
                                        [ngTemplateOutlet]="languageTemplate"
                                        [ngTemplateOutletContext]="{ $implicit: language?.value }"
                                    >
                                    </ng-template>
                                </ng-template>
                                <ng-template
                                    let-language
                                    pTemplate="item"
                                >
                                    <div>
                                        <ng-template
                                            [ngTemplateOutlet]="languageTemplate"
                                            [ngTemplateOutletContext]="{ $implicit: language?.value }"
                                        >
                                        </ng-template>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </li>
                        <li>
                            <a
                                id="navMenuLogout"
                                (click)="closeMenus(); logout()"
                            >
                                {{ "general.logout" | translate }}
                            </a>
                        </li>
                    </ul>
                </div>
            </ng-template>

            <div
                class="m-account"
                *ngIf="isAuthorized"
            >
                <div
                    class="m-nav-list-item"
                    *ngIf="authorizationInfo"
                >
                    <a
                        class="m-nav-link"
                        id="navHelpAnchor"
                        routerLink="/documentation/viewer/introduction"
                        title="{{ 'documentation.link' | translate }}"
                    >
                        <span class="m-account-item">
                            <app-svg-icon
                                [ngStyle]="{ fill: '#173232' }"
                                icon="circle-question"
                            />
                        </span>
                    </a>
                </div>

                <div
                    class="m-nav-list-item"
                    *ngIf="authorizationInfo"
                >
                    <app-notification-bubble></app-notification-bubble>
                </div>

                <ng-container *ngTemplateOutlet="logoutDropdown"></ng-container>
            </div>

            <div
                class="m-language"
                *ngIf="isAuthenticated !== null && isAuthenticated === false"
            >
                <div class="m-language-dropdown">
                    <ng-template
                        #languageTemplate
                        let-language
                    >
                        <app-svg-icon
                            [icon]="language"
                            [ngStyle]="{ fill: '#000' }"
                            file="assets/flag-icons.svg"
                        ></app-svg-icon>
                        {{ language | translateEnum: "language" }}
                    </ng-template>

                    <p-dropdown
                        id="navMenuLanguage"
                        #languageDropdown
                        *ngIf="languages"
                        [autoDisplayFirst]="false"
                        [ngModel]="translate.currentLang"
                        [options]="languages"
                        (ngModelChange)="setLanguage($event)"
                    >
                        <ng-template
                            let-language
                            pTemplate="selectedItem"
                        >
                            <ng-template
                                [ngTemplateOutlet]="languageTemplate"
                                [ngTemplateOutletContext]="{ $implicit: language?.value }"
                            >
                            </ng-template>
                        </ng-template>

                        <ng-template
                            let-language
                            pTemplate="item"
                        >
                            <div>
                                <ng-template
                                    [ngTemplateOutlet]="languageTemplate"
                                    [ngTemplateOutletContext]="{ $implicit: language.value }"
                                >
                                </ng-template>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
        </div>
    </div>
</header>
