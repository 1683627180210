<p-table
    #tableRef
    [(selection)]="selectedTasks"
    [columnResizeMode]="columnResizeMode"
    [columns]="relevantColumns"
    [customSort]="selectionBox"
    [lazy]="isLazy"
    [loading]="loading"
    [pageLinks]="pageLinks"
    [paginator]="paginator"
    [resizableColumns]="resizable"
    [rows]="internalRowCount"
    [scrollable]="scrollable"
    [selectionMode]="selectionMode"
    [styleClass]="styleClass"
    [totalRecords]="total"
    [value]="data"
    (onLazyLoad)="loadTableRows($event)"
    (onPage)="onPage($event)"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowDeselect($event)"
    (sortFunction)="customSort($event)"
    dataKey="id"
    scrollHeight="flex"
    sortField="name"
>
    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td
                class="m-table-empty-message"
                [attr.colspan]="relevantColumns.length"
            >
                {{ "table.empty" | translate }}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total"> {{ "table.total" | translate }}: {{ total }} </ng-container>
    </ng-template>

    <ng-template
        *ngIf="footer && !paginator"
        pTemplate="summary"
    >
        <ng-container *ngIf="total"> {{ "table.total" | translate }}: {{ total }} </ng-container>
    </ng-template>

    <ng-template
        *ngIf="headers"
        pTemplate="header"
    >
        <tr>
            <th
                *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field"
                [pResizableColumnDisabled]="!resizable || !column.resizable"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
                pResizableColumn
            >
                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <ng-container *ngSwitchCase="'commands'">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </ng-container>

                        <span class="m-column-title">
                            {{ column.header | translate }}
                        </span>

                        <p-sortIcon
                            *ngIf="sortable && column.sortable"
                            [field]="column.field"
                        >
                        </p-sortIcon>

                        <app-table-filter
                            class="m-column-filter"
                            *ngIf="filter"
                            [column]="column"
                            [table]="table"
                        >
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template
        let-task
        pTemplate="body"
    >
        <tr
            #row
            [attr.id]="task[table.dataKey]"
            [pSelectableRow]="task"
            [pSelectableRowDisabled]="!canSelectRow(task)"
        >
            <td
                *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="{ width: ['commands'].includes(column.field) ? '0' : null }"
                [ngSwitch]="column.field"
            >
                <ng-container *ngSwitchCase="'commands'">
                    <div class="flex">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button
                                *ngIf="command.rowValidFunc(task)"
                                [disabled]="command.disabledFunc()"
                                [icon]="command.icon"
                                [iconClass]="command.icon === 'duplicate' ? 'duplicate' : ''"
                                [text]="command.text"
                                (click)="command.click(task)"
                            >
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'isCompleted'">
                    <app-svg-icon
                        *ngIf="task.currentStatus.taskStatusId === 'finished'; else notCompleted"
                        icon="checkmark"
                        ngClass="fill-success"
                        tooltip="{{ 'task.completed' | translate }}"
                    ></app-svg-icon>
                    <ng-template #notCompleted>
                        <app-svg-icon
                            icon="pending"
                            ngClass="fill-warning"
                            tooltip="{{ 'task.notCompleted' | translate }}"
                        ></app-svg-icon>
                    </ng-template>
                </ng-container>

                <ng-container *ngSwitchCase="'assignment'">
                    {{ task.assignment ? task.assignment.name : "" }}
                </ng-container>

                <div
                    *ngSwitchCase="'currentStatus.taskTypeId'"
                    [ngStyle]="{ 'font-size': mini ? '10px' : 'inherit' }"
                >
                    <app-domain-data-field
                        [enumKey]="task.currentStatus.taskTypeId"
                        domainDataType="taskType"
                    >
                    </app-domain-data-field>

                    <ng-container *ngIf="mini">
                        <br />
                        <app-domain-data-field
                            [enumKey]="task.currentStatus.signMaterialId"
                            domainDataType="signMaterial"
                        >
                        </app-domain-data-field>
                        -
                        <app-domain-data-field
                            [enumKey]="
                                task.currentStatus.signCategoryLevel4Id ||
                                task.currentStatus.signCategoryLevel3Id ||
                                task.currentStatus.signCategoryLevel2Id ||
                                task.currentStatus.signCategoryId
                            "
                            domainDataType="signCategoryValue"
                        ></app-domain-data-field>
                    </ng-container>
                </div>

                <ng-container *ngSwitchCase="'currentStatus.signMaterialId'">
                    <app-domain-data-field
                        [enumKey]="task.currentStatus.signMaterialId"
                        domainDataType="signMaterial"
                    >
                    </app-domain-data-field>
                </ng-container>

                <ng-container *ngSwitchCase="'currentStatus.signCategoryId'">
                    <app-domain-data-field
                        [enumKey]="task.currentStatus.signCategoryId"
                        domainDataType="signCategoryValue"
                    >
                    </app-domain-data-field>
                    <ng-container *ngIf="task.currentStatus.signCategoryLevel2Id">
                        -
                        <app-domain-data-field
                            [enumKey]="task.currentStatus.signCategoryLevel2Id"
                            domainDataType="signCategoryValue"
                        >
                        </app-domain-data-field>
                    </ng-container>
                    <ng-container *ngIf="task.currentStatus.signCategoryLevel3Id">
                        -
                        <app-domain-data-field
                            [enumKey]="task.currentStatus.signCategoryLevel3Id"
                            domainDataType="signCategoryValue"
                        >
                        </app-domain-data-field>
                    </ng-container>
                    <ng-container *ngIf="task.currentStatus.signCategoryLevel4Id">
                        -
                        <app-domain-data-field
                            [enumKey]="task.currentStatus.signCategoryLevel4Id"
                            domainDataType="signCategoryValue"
                        >
                        </app-domain-data-field>
                    </ng-container>
                    <ng-container *ngIf="task.currentStatus.propertyConfiguration.showType">
                        -
                        <app-domain-data-field
                            [enumKey]="task.currentStatus.type"
                            domainDataType="markingPropertyType"
                        >
                        </app-domain-data-field>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'currentStatus.taskStatusId'">
                    <app-domain-data-field
                        [enumKey]="task.currentStatus.taskStatusId"
                        domainDataType="taskStatus"
                    >
                    </app-domain-data-field>
                </ng-container>

                <ng-container *ngSwitchCase="'location'">
                    {{ task.location?.address | address }}
                </ng-container>

                <ng-container *ngSwitchCase="'currentStatus.isMachineWork'">
                    <app-svg-icon
                        [fill]="getIconFillForBoolean(task.currentStatus.isMachineWork)"
                        [icon]="getIconForBoolean(task.currentStatus.isMachineWork)"
                    >
                    </app-svg-icon>
                </ng-container>

                <ng-container *ngSwitchCase="'currentStatus.availableFrom'">
                    {{ task.currentStatus.availableFrom | date: "dd/MM/yyyy" }}
                </ng-container>
                <ng-container *ngSwitchCase="'currentStatus.team'">
                    {{ task.currentStatus.team }}
                </ng-container>
                <ng-container *ngSwitchCase="'currentStatus.note'">
                    {{ task.currentStatus.note }}
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{ task[column.field] }}
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>
