import { AlertLevel } from "@ramudden/models/alert";
import { MeasuringPointLinkStatus } from "@ramudden/models/map-filter";
import { AnalysisType } from "@ramudden/models/measuring-point";
import { IOrganization, IUser } from "@ramudden/models/user";
import { IProjectSummary } from "@ramudden/models/web";

/**
 * Encapsulates all filter options in a single class.
 */
export class FilterState {
    // These lists are used in the UI
    public organizations: IOrganization[] = [];
    public projects: IProjectSummary[] = [];
    public types: AnalysisType[] = [];
    public linkStatus?: MeasuringPointLinkStatus = null;
    public alertLevels: AlertLevel[] = [];
    public searchTerm: string = null;
    public projectsForUserId: IUser = null;

    public clear() {
        this.projects = [];
        this.organizations = [];
        this.types = [];
        this.linkStatus = null;
        this.alertLevels = [];
        this.searchTerm = null;
        this.projectsForUserId = null;
    }

    public count() {
        let filterCount = 0;

        filterCount += this.projects.length;
        filterCount += this.organizations.length;
        filterCount += this.types.length;
        filterCount += this.alertLevels.length;
        filterCount += this.linkStatus ? 1 : 0;
        filterCount += this.searchTerm ? 1 : 0;
        filterCount += this.projectsForUserId !== null ? 1 : 0;

        return filterCount;
    }

    public isEmpty(): boolean {
        if (this.organizations.length > 0) return false;
        if (this.projects.length > 0) return false;
        if (this.types.length > 0) return false;
        if (this.alertLevels.length > 0) return false;
        if (this.linkStatus !== null) return false;
        if (this.searchTerm !== null) return false;
        if (this.projectsForUserId !== null) return false;
        return true;
    }
}

export class FilterStateChangedEventArgs {
    constructor(
        public state: FilterState,
        public shouldZoom: boolean,
    ) {}
}
