import { inject, Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    GuardResult,
    MaybeAsync,
    Router,
    RouterStateSnapshot,
} from "@angular/router";
import { BackendRights } from "@ramudden/models/backend-rights";
import { Rights } from "@ramudden/models/rights";
import { filter } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "../services/authentication.service";
import { GlobalEventsService } from "../services/global-events.service";

@Injectable()
export class AuthorizationGuard implements CanActivate {
    private readonly router = inject(Router);
    private readonly globalEventsService = inject(GlobalEventsService);
    private readonly authenticationService = inject(AuthenticationService);

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): MaybeAsync<GuardResult> {
        return this.globalEventsService.currentRights$.pipe(
            filter((rights) => rights && rights.backendRights.length > 0),
            map((rights) => this.checkRights(route, rights)),
        );
    }

    checkRights(route: ActivatedRouteSnapshot, rights: Rights) {
        const expectedRights = route.data?.rights as BackendRights[];
        if (!expectedRights) {
            return false; // do not allow navigation if rights are not configured
        }

        if (expectedRights.some((x) => rights.hasBackendRight(x))) {
            return true;
        }

        const availableLink = this.authenticationService.getNavigationLinks(rights).takeFirstOrDefault();
        if (!availableLink) {
            return false; // there is no link to which user can be navigated
        }

        return this.router.parseUrl(availableLink.route);
    }
}
