import { IArrowBoardConfiguration } from "./arrow-board-configuration";
import { ICctvConfiguration } from "./cctv-configuration";
import { ICountingZoneConfiguration } from "./counting-zone-configuration";
import { DeviceType } from "./device";
import { IDrivingLane } from "./driving-lane";
import { IExternalInputConfiguration } from "./external-input-configuration";
import { IFloatingCarConfiguration } from "./floating-car-configuration";
import { IGroupMeasuringPoint } from "./group";
import { ILocation, LocationCreator } from "./location";
import { IMeasuringPointAggregation } from "./measuring-point-aggregation";
import { ILightModeConfiguration } from "./mode-configuration";
import { IParkingConfiguration } from "./parking-configuration";
import { IProjectMeasuringPoint } from "./project";
import { IServiceModelBase } from "./servicemodelbase";
import { ISharedKey } from "./shared-key";
import { ITotemDisplayConfiguration } from "./totem-display-configuration";
import { IUpload } from "./upload";
import { IVehicleAnalysisTypeConfiguration } from "./vehicle-analysis-type-configuration";
import { IVmsModeConfiguration } from "./vms";

export enum AnalysisType {
    Car = "car",
    Bike = "bike",
    CarAndBike = "carAndBike",
    CarXPos = "carXPos",
    BikeXPos = "bikeXPos",
    Anpr = "anpr",
    CameraDetection = "cameraDetection",
    FloatingCar = "floatingCar",
    ExternalInput = "externalInput",
    Light = "light",
    Vms = "vms",
    Cctv = "cctv",
    TotemDisplay = "totemDisplay",
    Intersection = "document", // Internally still "document", outwards facing Intersection
    Aggregated = "aggregated",
    Radar = "radar",
    Pedestrian = "pedestrian",
    Parking = "parking",
    RailTransport = "railTransport",
    Tracker = "tracker",
    CountingZone = "countingZone",
    TrafficLight = "trafficLight",
    ArrowBoard = "arrowBoard",
    Vehicle = "vehicle",
}

export namespace AnalysisTypeGroup {
    export const PULSE_DETECTIONS = [
        AnalysisType.Car,
        AnalysisType.Bike,
        AnalysisType.CarAndBike,
        AnalysisType.Pedestrian,
        AnalysisType.RailTransport,
    ];
    export const POSITION_DETECTIONS = [AnalysisType.CarXPos, AnalysisType.BikeXPos];
    export const OTHER_DETECTIONS = [
        AnalysisType.Radar,
        AnalysisType.CameraDetection,
        AnalysisType.Anpr,
        AnalysisType.FloatingCar,
        AnalysisType.Parking,
    ];
    export const TRAFFIC_CONTROL = [
        AnalysisType.Cctv,
        AnalysisType.ExternalInput,
        AnalysisType.Light,
        AnalysisType.TotemDisplay,
        AnalysisType.Vms,
        AnalysisType.TrafficLight,
        AnalysisType.ArrowBoard,
    ];
    export const OTHER = [
        AnalysisType.Tracker,
        AnalysisType.Intersection,
        AnalysisType.Vehicle,
        AnalysisType.CountingZone,
        AnalysisType.Aggregated,
    ];
}

export interface IMeasuringPointNavigator extends IServiceModelBase {
    code: string;
    locationId: number;
    analysisTypeId: AnalysisType;
}

export interface IMeasuringPoint extends IServiceModelBase {
    code: string;
    description: string;
    from: string;
    to: string;
    fromTo: string;
    heading: number;
    analysisTypeId: AnalysisType;
    drivingLane: IDrivingLane;
    location: ILocation;
    locationId: number;
    uploads: IUpload[];
    vmsType: IVmsType;

    groups: IGroupMeasuringPoint[];

    projects: IProjectMeasuringPoint[];

    aggregations: IMeasuringPointAggregation[];
    vmsModeConfiguration: IVmsModeConfiguration;
    lightModeConfiguration: ILightModeConfiguration;
    externalInputConfiguration: IExternalInputConfiguration;
    floatingCarConfiguration: IFloatingCarConfiguration;
    parkingConfiguration: IParkingConfiguration;
    totemDisplayConfiguration: ITotemDisplayConfiguration;
    countingZoneConfiguration: ICountingZoneConfiguration;
    cctvConfiguration: ICctvConfiguration;
    arrowBoardConfiguration: IArrowBoardConfiguration;
    vehicleConfiguration: IVehicleAnalysisTypeConfiguration;

    sharedKey: ISharedKey;
    readonly: boolean;
    ownerId: number;

    // added for runtime type checking;
    isMeasuringPoint: boolean;
}

export class MeasuringPointUpdater {
    id: number;
    code: string;
    description: string;
    heading: number;
    from: string;
    to: string;
    drivingLaneId: number;
    analysisTypeId: AnalysisType;
    projectIds?: number[];
    vmsTypeId: VmsTypeValue;
    vmsModeConfiguration: IVmsModeConfiguration;
    lightModeConfiguration: ILightModeConfiguration;
    externalInputConfiguration: IExternalInputConfiguration;
    floatingCarConfiguration: IFloatingCarConfiguration;
    parkingConfiguration: IParkingConfiguration;
    totemDisplayConfiguration: ITotemDisplayConfiguration;
    countingZoneConfiguration: ICountingZoneConfiguration;
    cctvConfiguration: ICctvConfiguration;
    arrowBoardConfiguration: IArrowBoardConfiguration;
    vehicleConfiguration: IVehicleAnalysisTypeConfiguration;

    constructor(existing: IMeasuringPoint) {
        if (!existing) return;

        this.id = existing.id;
        this.code = existing.code;
        this.description = existing.description;
        this.heading = existing.heading;
        this.from = existing.from;
        this.to = existing.to;
        this.drivingLaneId = existing.drivingLane ? existing.drivingLane.id : null;
        this.analysisTypeId = existing.analysisTypeId;
        this.projectIds = existing.projects?.map((x) => x.projectId);
        this.vmsModeConfiguration = existing.vmsModeConfiguration;
        this.lightModeConfiguration = existing.lightModeConfiguration;
        this.externalInputConfiguration = existing.externalInputConfiguration;
        this.floatingCarConfiguration = existing.floatingCarConfiguration;
        this.parkingConfiguration = existing.parkingConfiguration;
        this.totemDisplayConfiguration = existing.totemDisplayConfiguration;
        this.countingZoneConfiguration = existing.countingZoneConfiguration;
        this.arrowBoardConfiguration = existing.arrowBoardConfiguration;
        this.vmsTypeId = existing.vmsType?.typeId;
        this.cctvConfiguration = existing.cctvConfiguration;
        this.vehicleConfiguration = existing.vehicleConfiguration;
    }
}

export class MeasuringPointCreator extends MeasuringPointUpdater {
    locationId?: number;
    location?: LocationCreator;

    constructor() {
        super(null);
    }
}

export interface IVmsType {
    typeId: VmsTypeValue;
    resolutionWidth: number;
    resolutionHeight: number;
    supportsAnimation: boolean;
    deviceTypeId: DeviceType;
}

export enum VmsTypeValue {
    EboVanWeelTrailer = "eboVanWeelTrailer",
    EboVanWeelPorch_1 = "eboVanWeelPorch_1",
    EboVanWeelPorch_2 = "eboVanWeelPorch_2",
    EboVanWeelPorch_3 = "eboVanWeelPorch_3",
    EboVanWeelPorch_4 = "eboVanWeelPorch_4",
    AvsTrailer = "avsTrailer",
}
