import { IDateTimeRange } from "./datetime-range";
import { IDeviceNavigator } from "./device";
import { IDeviceDepotNavigator } from "./device-depot";
import { IMeasurement, MeasurementCreator } from "./measurement";
import { IServiceModelBase } from "./servicemodelbase";
import { IUserSummary } from "./user";

export interface IDeviceLink extends IServiceModelBase {
    id: number;
    validFrom: Date;
    validUntil?: Date;
    device: IDeviceNavigator;
    creator: IUserSummary;
    gapfiller: boolean;
    measurements: IMeasurement[];
    depot: IDeviceDepotNavigator;
}

export interface IDeviceLinkConflicts {
    conflicts: IDeviceLinkConflict[];
    newLink: IDeviceLink;
}

export interface IDeviceLinkConflict {
    original: IDeviceLink;
    changed: IDateTimeRange;
    showChangedFrom: boolean;
    showChangedUntil: boolean;
}

export class DeviceLinkCreator {
    validFrom: Date;
    validUntil?: Date;
    deviceId: number;
    preview?: boolean;
    depotId: number;
    measurements: MeasurementCreator[];
}

export class DeviceLinkUpdater extends DeviceLinkCreator {
    id: number;

    constructor(existing: IDeviceLink) {
        super();

        this.id = existing.id;
        this.validFrom = existing.validFrom;
        this.validUntil = existing.validUntil;
        this.deviceId = existing.device.id;
        this.depotId = existing.depot ? existing.depot.id : null;
        this.measurements = existing.measurements ? existing.measurements.map((x) => new MeasurementCreator(x)) : [];
    }
}
