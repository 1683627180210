<details
    class="m-dropdown"
    #notificationsDropdown
    (clickOutside)="closeNotificationDropdown()"
>
    <summary
        class="m-dropdown-summary"
        (click)="markNotificationsAsRead()"
    >
        <span class="m-account-item">
            <app-svg-icon icon="bell" />
            <ng-container *ngIf="unreadNotificationsCount + unreadStickyNotificationsCount > 0">
                <span class="m-alert-badge">
                    <small>{{ unreadNotificationsCount + unreadStickyNotificationsCount }}</small>
                </span>
            </ng-container>
        </span>
    </summary>

    <div
        class="m-dropdown-content"
        style="right: 0px"
    >
        <div class="notification">
            <div class="notification__content">
                @for (stickyNotification of stickyNotifications; let last = $last; track stickyNotification.id) {
                    <div
                        class="m-alert p-16"
                        [ngClass]="{ 'is-unread': !stickyNotification.seenOn }"
                    >
                        <div class="m-alert-level">
                            <app-svg-icon
                                *ngIf="stickyNotification.levelId === 'information'"
                                [ngStyle]="{ fill: '#1387F1' }"
                                divClass="icon-16"
                                icon="circle-info"
                            />
                            <app-svg-icon
                                *ngIf="stickyNotification.levelId === 'warning'"
                                [ngStyle]="{ fill: '#F1C013' }"
                                divClass="icon-16"
                                icon="circle-exclamation"
                            />
                            <app-svg-icon
                                *ngIf="stickyNotification.levelId === 'error'"
                                [ngStyle]="{ fill: '#EE3535' }"
                                divClass="icon-16"
                                icon="circle-xmark"
                            />
                        </div>

                        <div>
                            <p class="m-alert-date">
                                <small>{{
                                    stickyNotification.createDate | momentDateTime: true : false : translate.currentLang
                                }}</small>
                            </p>

                            <ng-container *ngIf="stickyNotification.message">
                                <p
                                    class="m-alert-message"
                                    [innerHtml]="
                                        stickyNotification.message | translateMessage: stickyNotification.categoryId
                                    "
                                ></p>

                                <p>
                                    <a
                                        class="m-navigator"
                                        (click)="navigateToNotificationPreview(notification)"
                                    >
                                        <small>{{ "notifications.viewOnMap" | translate }}</small>
                                    </a>
                                </p>
                            </ng-container>
                        </div>
                    </div>

                    @if (!last) {
                        <hr class="my-0" />
                    }
                }
                @for (notification of notifications; let last = $last; track notification.id) {
                    <div
                        class="m-alert p-16"
                        [ngClass]="{ 'is-unread': !notification.seenOn }"
                    >
                        <div class="m-alert-level">
                            <app-svg-icon
                                *ngIf="notification.levelId === 'information'"
                                [ngStyle]="{ fill: '#1387F1' }"
                                divClass="icon-16"
                                icon="circle-info"
                            />
                            <app-svg-icon
                                *ngIf="notification.levelId === 'warning'"
                                [ngStyle]="{ fill: '#F1C013' }"
                                divClass="icon-16"
                                icon="circle-exclamation"
                            />
                            <app-svg-icon
                                *ngIf="notification.levelId === 'error'"
                                [ngStyle]="{ fill: '#EE3535' }"
                                divClass="icon-16"
                                icon="circle-x"
                            />
                        </div>

                        <div>
                            <p class="m-alert-date">
                                <small>{{
                                    notification.createDate | momentDateTime: true : false : translate.currentLang
                                }}</small>
                            </p>

                            @if (notification.message) {
                                <p
                                    class="m-alert-message"
                                    [innerHtml]="notification.message | translateMessage"
                                ></p>

                                <p>
                                    <a
                                        class="m-navigator"
                                        (click)="navigateToNotificationPreview(notification)"
                                    >
                                        <small>{{ "notifications.readMore" | translate }}</small>
                                    </a>
                                </p>
                            }
                        </div>
                    </div>

                    @if (!last) {
                        <hr class="my-0" />
                    }
                }
            </div>

            <div class="notification__footer">
                <a
                    class="m-alert-showall m-navigator"
                    [routerLink]="['/notifications']"
                    (click)="closeNotificationDropdown()"
                >
                    {{ "notifications.viewAll" | translate }}</a
                >

                <a
                    class="m-alert-showall m-navigator"
                    *ngIf="showMock"
                    (click)="createMockNotification()"
                >
                    Create Mock Notification
                </a>
            </div>
        </div>
    </div>
</details>
