import { Injectable } from "@angular/core";
import { StringUtils, SubscriptionManager } from "@ramudden/core/utils";
import { CacheOptions } from "@ramudden/data-access/resource/api";
import { UserApi } from "@ramudden/data-access/resource/user.api";
import { ISearchResult, ServiceRequestOptions } from "@ramudden/models/search";
import { IUser } from "@ramudden/models/user";
import { Observable, Subject } from "rxjs";
import { EventService } from "./event.service";

@Injectable({
    providedIn: "root",
})
export class UsersService {
    private readonly subscriptionManager = new SubscriptionManager();

    users: IUser[];
    private readonly usersSubject = new Subject<IUser[]>();
    private readonly userSubject = new Subject<IUser>();
    private loadUsersPromise: Promise<IUser[]>;

    constructor(
        private readonly userApi: UserApi,
        private readonly eventService: EventService,
    ) {}

    clear() {
        this.clearUsers();
    }

    get isLoading(): boolean {
        return !!this.loadUsersPromise;
    }

    async reload() {
        if (this.users) {
            this.clearUsers();
            await this.loadUsers();
        }
    }

    // Keys are used for keeping track of subscriptions
    // So that multiples of the same component can subscribe to the same observables
    createKey(): string {
        return StringUtils.createKey();
    }

    unsubscribe(key: string) {
        const subscriptions = this.subscriptionManager.subscriptions;
        for (const subscriptionKey in subscriptions) {
            if (!subscriptions.hasOwnProperty(subscriptionKey)) continue;
            if (!subscriptionKey.startsWith(key)) continue;

            this.subscriptionManager.remove(subscriptionKey);
        }
    }

    //#region Users

    private getUsers(): IUser[] {
        const users = this.users || [];

        return users.orderBy((x) => x.firstName);
    }

    private onUserUpdate(): Observable<IUser> {
        return this.userSubject.asObservable();
    }

    subscribeToUserUpdate(key: string, onUpdate?: (updatedUser: IUser) => void) {
        const subscription = this.onUserUpdate().subscribe(onUpdate);
        this.subscriptionManager.add(`${key}userUpdate`, subscription);
    }

    private onUsersUpdate(): Observable<IUser[]> {
        return this.usersSubject.asObservable();
    }

    subscribeToUsers(
        key: string,
        callback?: (res: IUser[]) => void,
        errorCallback?: (res: Response) => void,
        forceUpdate = false,
    ) {
        const onSuccess = () => {
            if (callback) callback(this.getUsers());
        };

        if (!errorCallback) errorCallback = () => {};
        const onError = (response: Response) => {
            if (errorCallback) errorCallback(response);
        };

        const subscription = this.onUsersUpdate().subscribe(onSuccess, onError);

        if (callback && this.users) {
            callback(this.getUsers());
        }

        if (forceUpdate || !this.users) {
            this.loadUsers();
        }

        this.subscriptionManager.add(`${key}users`, subscription);
    }

    updateUsers() {
        this.eventService.processNext(this.usersSubject, this.getUsers, this);
    }

    loadUsers(): Promise<IUser[]> {
        if (!this.loadUsersPromise) {
            this.loadUsersPromise = new Promise<IUser[]>((resolve, reject) => {
                const onSuccess = (searchResult: ISearchResult<IUser>) => {
                    this.fillUsers(searchResult.data);

                    resolve(this.users);
                };

                const onError = (response: any) => {
                    if (response.status !== 304) {
                        this.clearUsers();
                        reject(response);
                    } else {
                        resolve(this.users);
                    }
                };

                const serviceRequestOptions = new ServiceRequestOptions();

                const cacheOptions = new CacheOptions();
                cacheOptions.pushCacheResult = false;
                cacheOptions.pushCacheResultOnNotModified = !this.users;

                this.userApi.search$(null, serviceRequestOptions, cacheOptions).subscribe(onSuccess, onError);
            });

            const onPromiseEnd = () => {
                this.loadUsersPromise = null;
            };

            this.loadUsersPromise.then(onPromiseEnd, onPromiseEnd);
        }

        return this.loadUsersPromise;
    }

    private fillUsers(users: IUser[]) {
        this.users = users;
        this.updateUsers();
    }

    async addOrUpdateUser(user: IUser) {
        const existingUser = this.users.filter((x) => x.id === user.id).takeSingleOrDefault();
        if (existingUser) {
            Object.assign(existingUser, user);
            this.updateUser(existingUser);
            return;
        }

        this.users = [user].concat(this.users);
        this.updateUsers();
    }

    updateUser(user: IUser) {
        this.eventService.processNext(this.userSubject, user);
    }

    removeUser(user: IUser) {
        user.isObsolete = true;

        this.users = this.users.filter((x) => x !== user);
        this.updateUsers();
    }

    async getUser$(callbackfn: (value: IUser) => boolean): Promise<IUser> {
        if (!this.users) {
            await this.loadUsers();
        }

        if (this.users) {
            for (const user of this.users) {
                if (callbackfn(user)) return user;
            }
        }

        return null;
    }

    clearUsers() {
        this.users = null;
        this.loadUsersPromise = null;
        this.updateUsers();
    }

    //#endregion Users
}
