import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SubscriptionManager } from "@ramudden/core/utils";
import { NotificationApi } from "@ramudden/data-access/resource/notification.api";
import { AlertLevel } from "@ramudden/models/alert";
import { MessageTemplate } from "@ramudden/models/message";
import { INotification, NotificationCategory, NotificationLevel } from "@ramudden/models/notification";
import { INewNotificationAddedArguments } from "@ramudden/models/realtime";
import { FilterOperator, ISearchResult, SearchParameters } from "@ramudden/models/search";
import { AuthorizationInfo } from "@ramudden/models/user";
import { environment } from "../../../../../environments/environment";
import { GlobalEventsService } from "../../../../services/global-events.service";
import { NotificationRealtimeService } from "../../../../services/realtime/notification-realtime.service";
import { MomentDateTimePipe } from "../../../../shared/pipes/datetime.pipe";
import { SharedModule } from "../../../../shared/shared.module";
import { FilterService, FilterServiceFields } from "../../../installations/services/filter.service";

@Component({
    selector: "app-notification-bubble",
    templateUrl: "./notification-bubble.component.html",
    styleUrls: ["./notification-bubble.component.scss"],
    imports: [SharedModule, MomentDateTimePipe],
    standalone: true,
})
export class NotificationBubbleComponent implements OnInit, OnDestroy {
    private notificationsDropdown: HTMLDetailsElement;

    @ViewChild("notificationsDropdown", { static: false }) set setNotificationsDropdown(
        notificationsDropdown: ElementRef<HTMLDetailsElement>,
    ) {
        this.notificationsDropdown = notificationsDropdown ? notificationsDropdown.nativeElement : null;
    }

    unreadNotificationsCount = 0;
    unreadStickyNotificationsCount = 0;
    notifications: INotification[] = [];
    stickyNotifications: INotification[] = [];
    NotificationCategory = NotificationCategory;

    private subscriptionManager = new SubscriptionManager();
    private authorizationInfo: AuthorizationInfo;

    private readonly filterService = inject(FilterService);
    private readonly router = inject(Router);

    get showMock(): boolean {
        return this.authorizationInfo?.isActualDomainAdministrator && !environment.production;
    }

    constructor(
        private readonly globalEventsService: GlobalEventsService,
        readonly translate: TranslateService,
        private readonly notificationRealtimeService: NotificationRealtimeService,
        private readonly notificationApi: NotificationApi,
    ) {
        if (!environment.production) {
            const onAuthorizationInfoSubscription = this.globalEventsService.authorizationInfo$.subscribe(
                (authorizationInfo) => {
                    this.authorizationInfo = authorizationInfo;
                },
            );

            this.subscriptionManager.add("onAuthorizationInfo", onAuthorizationInfoSubscription);
        }
    }

    ngOnInit() {
        this.subscriptionManager.add(
            "isAuthenticatedSubscription",
            this.globalEventsService.isAuthenticated$.subscribe((value) => {
                if (value === true) {
                    const newNotificationSubscription = this.notificationRealtimeService.subscribeToNewNotifications(
                        this.onNotificationAdded.bind(this),
                    );
                    this.subscriptionManager.add("newNotificationSubscription", newNotificationSubscription); // This makes sure we unsubscribe when this component is destroyed

                    const allNotificationsSeenSubscription =
                        this.notificationRealtimeService.subscribeToAllNotificationsSeen(
                            this.onAllNotificationsSeen.bind(this),
                        );
                    this.subscriptionManager.add("allNotificationsSeenSubscription", allNotificationsSeenSubscription); // This makes sure we unsubscribe when this component is destroyed
                }
            }),
        );
        this.getNotifications();
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
        this.notificationRealtimeService.unsubscribe();
    }

    onAllNotificationsSeen() {
        this.unreadNotificationsCount = 0;
        this.unreadStickyNotificationsCount = 0;
    }

    onNotificationAdded(_args: INewNotificationAddedArguments) {
        this.getNotifications();
    }

    private fillParsedQueryParameters(data: ISearchResult<INotification>) {
        // fill parsedQueryParameters
        data.data.forEach((notification) => {
            try {
                notification.message.parsedQueryParameters = JSON.parse(notification.message.queryParameters);
            } catch {
                notification.message.parsedQueryParameters = {};
            }
        });
    }

    getNotifications() {
        // Get sticky notifications
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];
        searchParameters.filter.push({ field: "isSticky", value: "true", operator: FilterOperator.equals });
        this.notificationApi.search$(searchParameters, null, false).subscribe((data: ISearchResult<INotification>) => {
            this.fillParsedQueryParameters(data);
            this.stickyNotifications = data.data;
            this.unreadStickyNotificationsCount = data.data.filter((x) => !x.seenOn).length;
        });

        // Get unread notifications from server
        searchParameters.filter = [];
        searchParameters.filter.push({ field: "isSticky", value: "false", operator: FilterOperator.equals });
        searchParameters.filter.push({
            field: "onlyUnreadNotifications",
            value: "true",
            operator: FilterOperator.equals,
        });
        this.notificationApi.search$(searchParameters, null, false).subscribe((data: ISearchResult<INotification>) => {
            if (data.total == 0) {
                this.unreadNotificationsCount = 0;
                // Get 3 latest notifications
                searchParameters.filter = [];
                searchParameters.filter.push({ field: "isSticky", value: "false", operator: FilterOperator.equals });
                searchParameters.take = 3;
                this.notificationApi
                    .search$(searchParameters, null, false)
                    .subscribe((data: ISearchResult<INotification>) => {
                        this.fillParsedQueryParameters(data);
                        this.notifications = data.data;
                    });
            } else {
                this.fillParsedQueryParameters(data);
                this.unreadNotificationsCount = data.total;
                this.notifications = data.data;
            }
        });
    }

    markNotificationsAsRead() {
        this.notificationApi.markAllAsRead$().subscribe(() => {});
    }

    closeNotificationDropdown() {
        if (!this.notificationsDropdown) {
            return;
        }

        if (this.notificationsDropdown.open) {
            this.notificationsDropdown.open = false;
        }
    }

    createMockNotification() {
        this.notifications.push({
            id: 1337,
            createDate: new Date(),
            levelId: NotificationLevel.Error,
            isSticky: true,
            categoryId: NotificationCategory.Alert,
            seenOn: null,
            message: {
                parameters: {},
                relativeUrl: "/locations",
                parsedQueryParameters: {
                    [FilterServiceFields.alertLevels]: `${AlertLevel.info},${AlertLevel.warning},${AlertLevel.error}`,
                },
            },
        } as INotification);
    }

    navigateToNotificationPreview(notification: INotification) {
        if (this.getMaasRelatedNotificationTemplates().contains(notification.message.messageTemplateId)) {
            this.router.navigate([notification.message.relativeUrl], {
                queryParams: notification.message.parsedQueryParameters,
            });
            return;
        }

        this.filterService.filterState.clear();
        this.filterService.initializeFilterStateFromQueryParams(notification.message.parsedQueryParameters);
        this.filterService.notifyFilterStateChanged(notification.message.relativeUrl, true);
    }

    private getMaasRelatedNotificationTemplates(): MessageTemplate[] {
        return [
            MessageTemplate.TeamCheckedInOnPlannedEvent,
            MessageTemplate.TeamConfigModifiedOnPlannedAssignment,
            MessageTemplate.TeamConfigModifiedOnPlannedEvent,
            MessageTemplate.TeamModifiedOnPlannedEvent,
            MessageTemplate.VehicleConfigModifiedOnPlannedAssignment,
            MessageTemplate.VehicleConfigModifiedOnPlannedEvent,
        ];
    }
}
