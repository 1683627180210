import { AnalysisType, IMeasuringPoint } from "./measuring-point";
import { IProject } from "./project";
import { IServiceModelBase } from "./servicemodelbase";
import { IOrganization, Roles } from "./user";

export enum AlertType {
    trafficLightStatus = "trafficLightStatus", //Obsolete
    installationStatus = "installationStatus",
    batteryStatus = "batteryStatus",
    pulseQuality = "pulseQuality",
    noNewStatusUpdate = "noNewStatusUpdate",
    impactDetected = "impactDetected",
}

export enum AlertLevel {
    info = "info",
    warning = "warning",
    error = "error",
}

export class AlertConfigurationTemplateCreator {
    name: string;
    organizationId: number;
    analysisTypeIds: string[];
}

export interface IValidAlertType {
    alertTypeId: AlertType;
    analysisTypeId: AnalysisType;
}

export class AlertConfigurationTemplateUpdater extends AlertConfigurationTemplateCreator {
    id: number;
}
export interface IAlertConfigurationTemplate extends IServiceModelBase {
    name: string;
    organization: IOrganization;
    organizationId: number;
    alertConfigurationGroups: IAlertConfigurationGroup[];
}

export interface IAlertConfigurationGroup extends IServiceModelBase {
    projectId?: number;
    project?: IProject;
    templateId: number;
    template?: IAlertConfigurationTemplate;
    analysisTypeId?: AnalysisType;
    alertConfigurations: IAlertConfiguration[];
    // locally used
    suggestions: AlertType[];
}
export interface IAlertConfiguration extends IServiceModelBase {
    alertTypeId?: AlertType;
    roleId?: Roles;
    remarks: string;
    configuration: AlertParametersConfiguration;
}

export class AlertConfigurationGroupCreator {
    analysisTypeIds: AnalysisType[];
}

export class AlertConfigurationCreator {
    AlertConfigurationGroupId: number;
    alertTypeId: AlertType;
    roleId: Roles;
    remarks: string;
    configuration: AlertParametersConfiguration;
}

export class AlertConfigurationUpdater extends AlertConfigurationCreator {
    id: number;
}
export class AlertParametersConfiguration {
    isPercentage: boolean;
    thresholdInfo: number;
    thresholdError: number;
    thresholdWarning: number;
    alertLevelId: AlertLevel;
    thresholdHoursUntilLastStatusError: number;
    thresholdHoursUntilLastStatusWarning: number;
    thresholdHoursUntilLastStatusInfo: number;
    thresholdMinutesUntilLastStatusError: number;
    thresholdMinutesUntilLastStatusWarning: number;
    thresholdMinutesUntilLastStatusInfo: number;
}

export interface IAlert extends IServiceModelBase {
    alertConfiguration: IAlertConfiguration;
    measuringPoint?: IMeasuringPoint;
    alertLevelId?: AlertLevel;
    alertTimestamp: Date;
}
