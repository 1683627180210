import { IMeasuringPointNavigator } from "./measuring-point";
import { IServiceModelBase } from "./servicemodelbase";
import { AnalyzerConfiguration } from "./upload";
import { IMeasuringPointSummary } from "./web";

export interface IMeasurement extends IServiceModelBase {
    measuringPoint: IMeasuringPointNavigator;
    measuringPointSummary: IMeasuringPointSummary;
    analyzerConfiguration?: AnalyzerConfiguration;
    tubes?: string[]; // Purely as a shortcut
}

export class MeasurementCreator {
    constructor(existing: IMeasurement = null) {
        if (!existing) return;
        this.measuringPointId = existing.measuringPoint.id;
        this.analyzerConfiguration = existing.analyzerConfiguration;
    }

    measuringPointId: number;
    analyzerConfiguration: AnalyzerConfiguration;
}
