<p-table
    #tableRef
    [columns]="relevantColumns"
    [hidden]="hidden"
    [loading]="loading"
    [pageLinks]="pageLinks"
    [paginator]="paginator"
    [rows]="internalRowCount"
    [scrollable]="scrollable"
    [scrollHeight]="scrollHeight"
    [selectionMode]="selectionMode"
    [sortField]="useDefaultSort ? 'code' : ''"
    [styleClass]="styleClass"
    [value]="data"
    (onPage)="onPage($event)"
    (onRowReorder)="reorder()"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowDeselect($event)"
    dataKey="id"
>
    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td
                class="m-table-empty-message"
                [attr.colspan]="relevantColumns.length"
            >
                {{ "table.empty" | translate }}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total"> {{ "table.total" | translate }}: {{ total }} </ng-container>
    </ng-template>

    <ng-template
        *ngIf="footer"
        pTemplate="summary"
    >
        <ng-container *ngIf="total && !paginator"> {{ "table.total" | translate }}: {{ total }} </ng-container>
    </ng-template>

    <ng-template
        *ngIf="headers"
        pTemplate="header"
    >
        <tr>
            <th
                *ngFor="let column of relevantColumns; trackBy: columnTrackByFn; let i = index"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
            >
                <div class="m-column-header">
                    <span
                        class="m-column-title"
                        *ngSwitchCase="'includeForwardDirection'"
                        style="text-align: center"
                    >
                        <app-svg-icon
                            icon="arrow-right"
                            tooltip="{{ 'measuringPoints.primaryDirection' | translate }}"
                        >
                        </app-svg-icon>

                        <input
                            [(ngModel)]="isAllForwardSelected"
                            (change)="bulkSelectChanged('forward')"
                            (click)="stopEvent($event)"
                            type="checkbox"
                        />
                    </span>

                    <span
                        class="m-column-title"
                        *ngSwitchCase="'includeReverseDirection'"
                        style="text-align: center"
                    >
                        <app-svg-icon
                            icon="arrow-left"
                            tooltip="{{ 'measuringPoints.reverseDirection' | translate }}"
                        >
                        </app-svg-icon>

                        <input
                            [(ngModel)]="isAllReverseSelected"
                            (change)="bulkSelectChanged('reverse')"
                            (click)="stopEvent($event)"
                            type="checkbox"
                        />
                    </span>

                    <span
                        class="m-column-title"
                        *ngSwitchCase="'includeSum'"
                        style="text-align: center"
                    >
                        <app-svg-icon
                            icon="arrow-sum"
                            tooltip="{{ 'measuringPoints.sumDirection' | translate }}"
                        >
                        </app-svg-icon>

                        <input
                            [(ngModel)]="isAllSumSelected"
                            (change)="bulkSelectChanged('sum')"
                            (click)="stopEvent($event)"
                            type="checkbox"
                        />
                    </span>

                    <span
                        class="m-column-title"
                        *ngSwitchCase="'commands'"
                        style="text-align: center"
                    >
                        <div
                            class="m-btn-group"
                            style="margin-top: 0"
                        >
                            <app-icon-button
                                *ngIf="deleteCommand && data?.length > 0"
                                [style]="{ 'min-height': '24px' }"
                                (click)="clearData()"
                                icon="delete"
                                text="form.delete"
                            >
                            </app-icon-button>
                        </div>
                    </span>

                    <ng-container *ngSwitchDefault>
                        <div class="m-column-header-wrapper">
                            <span class="m-column-title">
                                {{ column.header | translate }}
                            </span>

                            <span
                                class="m-column-title"
                                *ngIf="i == 1"
                                style="text-align: center"
                            >
                                <a (click)="alphabeticalSorting()">
                                    <app-svg-icon
                                        icon="a-z-sortIcon"
                                        tooltip="{{ 'measuringPoints.a-zSorting' | translate }}"
                                    >
                                    </app-svg-icon>
                                </a>
                            </span>

                            <p-sortIcon
                                *ngIf="sortable && column.sortable"
                                [field]="column.field"
                            >
                            </p-sortIcon>

                            <app-table-filter
                                class="m-column-filter"
                                *ngIf="filter"
                                [column]="column"
                                [table]="table"
                            >
                            </app-table-filter>
                        </div>

                        <app-table-filter-preview [column]="column"></app-table-filter-preview>
                    </ng-container>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template
        let-index="rowIndex"
        let-measuringPoint
        pTemplate="body"
    >
        <tr
            [pReorderableRow]="index"
            [pSelectableRow]="measuringPoint"
        >
            <td
                *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="{ width: ['commands', 'expand', 'reorder'].includes(column.field) ? '0' : null }"
                [ngSwitch]="column.field"
            >
                <ng-container *ngSwitchCase="'reorder'">
                    <div class="flex justify-center">
                        <i
                            class="fa fa-bars"
                            pReorderableRowHandle
                        ></i>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.code'">
                    {{ measuringPoint.code }}
                </ng-container>

                <ng-container *ngSwitchCase="'includeForwardDirection'">
                    <input
                        class="m-input-checkbox"
                        [(ngModel)]="measuringPoint.includeForwardDirection"
                        [disabled]="readonly"
                        (change)="updateIsAllForwardSelected()"
                        type="checkbox"
                    />
                </ng-container>

                <ng-container *ngSwitchCase="'includeReverseDirection'">
                    <input
                        class="m-input-checkbox"
                        [(ngModel)]="measuringPoint.includeReverseDirection"
                        [disabled]="readonly"
                        (change)="updateIsAllReverseSelected()"
                        type="checkbox"
                    />
                </ng-container>

                <ng-container *ngSwitchCase="'includeSum'">
                    <input
                        class="m-input-checkbox"
                        [(ngModel)]="measuringPoint.includeSum"
                        [disabled]="readonly"
                        (change)="updateIsAllSumSelected()"
                        type="checkbox"
                    />
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="flex">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button
                                *ngIf="command.rowValidFunc(measuringPoint)"
                                [disabled]="command.disabledFunc()"
                                [icon]="command.icon"
                                [text]="command.text"
                                (click)="command.click(measuringPoint)"
                            >
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{ measuringPoint[column.field] }}
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>
