import { NotificationLevel } from "./notification";
import { IServiceModelBase } from "./servicemodelbase";

export interface IMessage extends IServiceModelBase {
    messageTemplateId: MessageTemplate;
    relativeUrl: string;
    parameters: MessageParameters;
    queryParameters: string;
    parsedQueryParameters: any;
}

export class MessageParameters {
    values: { [key: string]: string };
    infoAlerts?: number;
    warningAlerts?: number;
    errorAlerts?: number;
}

export class MessageCreator {
    messageTemplateId: MessageTemplate;
    relativeUrl: string;
    levelId: MessageLevel;
    parameters: MessageParameters;
    userIds: number[];
    notificationLevelId: NotificationLevel;
}

export enum MessageTemplate {
    TeamModifiedOnPlannedEvent = "teamModifiedOnPlannedEvent",
    TeamConfigModifiedOnPlannedEvent = "teamConfigModifiedOnPlannedEvent",
    TeamCheckedInOnPlannedEvent = "teamCheckedInOnPlannedEvent",
    TeamConfigModifiedOnPlannedAssignment = "teamConfigModifiedOnPlannedAssignment",
    VehicleConfigModifiedOnPlannedEvent = "vehicleConfigModifiedOnPlannedEvent",
    VehicleConfigModifiedOnPlannedAssignment = "vehicleConfigModifiedOnPlannedAssignment",
    AlertsActive = "alertsActive",
}

export enum MessageLevel {
    Information = "information",
    Warning = "warning",
    Error = "error",
}
