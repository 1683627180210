<p-table
    id="measuringPointTable"
    #tableRef
    [columnResizeMode]="columnResizeMode"
    [columns]="relevantColumns"
    [customSort]="selectionBox"
    [lazy]="isLazy"
    [loading]="loading"
    [pageLinks]="pageLinks"
    [paginator]="paginator"
    [resizableColumns]="resizable"
    [rows]="internalRowCount"
    [scrollable]="true"
    [selectionMode]="selectionMode"
    [styleClass]="styleClass"
    [totalRecords]="total"
    [value]="data"
    (onLazyLoad)="loadTableRows($event)"
    (onPage)="onPage($event)"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowDeselect($event)"
    (sortFunction)="customSort($event)"
    dataKey="id"
    scrollHeight="flex"
    sortField="code"
>
    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td
                class="m-table-empty-message"
                [attr.colspan]="relevantColumns.length"
            >
                {{ "table.empty" | translate }}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total"> {{ "table.total" | translate }}: {{ total }} </ng-container>
    </ng-template>

    <ng-template
        *ngIf="footer && !paginator"
        pTemplate="summary"
    >
        <ng-container *ngIf="total"> {{ "table.total" | translate }}: {{ total }} </ng-container>
    </ng-template>

    <ng-template
        *ngIf="headers"
        pTemplate="header"
    >
        <tr>
            <th
                *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field"
                [pResizableColumnDisabled]="!resizable || !column.resizable"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
                pResizableColumn
            >
                <ng-container *ngSwitchCase="'selectionBox'">
                    <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                </ng-container>

                <div class="m-column-header">
                    <div class="m-column-header-wrapper">
                        <span class="m-column-title">
                            {{ column.header | translate }}
                        </span>

                        <p-sortIcon
                            *ngIf="sortable && column.sortable"
                            [field]="column.field"
                        >
                        </p-sortIcon>

                        <app-table-filter
                            class="m-column-filter"
                            *ngIf="filter"
                            [column]="column"
                            [table]="table"
                        >
                        </app-table-filter>
                    </div>

                    <app-table-filter-preview [column]="column"></app-table-filter-preview>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template
        let-measuringPoint
        pTemplate="body"
    >
        <tr
            #row
            [attr.id]="measuringPoint[table.dataKey]"
            [pSelectableRow]="measuringPoint"
            [pSelectableRowDisabled]="!canSelectRow(measuringPoint)"
        >
            <td
                *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="{ width: ['commands', 'expand', 'selectionBox'].includes(column.field) ? '0' : null }"
                [ngSwitch]="column.field"
            >
                <ng-container *ngSwitchCase="'selectionBox'">
                    <input
                        class="m-input-checkbox"
                        [checked]="isSelected(measuringPoint)"
                        [disabled]="!canSelectRow(measuringPoint)"
                        (click)="handleSelectionBoxClick(measuringPoint, $event)"
                        type="checkbox"
                    />
                </ng-container>

                <ng-container *ngSwitchCase="'analysisTypeIds'">
                    <div
                        class="flex justify-center"
                        style="width: 100%"
                    >
                        <span class="m-circle m-circle-grey">
                            <app-svg-icon
                                [icon]="measuringPoint.analysisTypeId"
                                [tooltip]="getMeasuringPointAnalysisTypeTranslation(measuringPoint.analysisTypeId)"
                            >
                            </app-svg-icon>
                        </span>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'fromTo'">
                    {{ measuringPoint.from }}
                    <span
                        class="m-arrow-right"
                        *ngIf="measuringPoint.from || measuringPoint.to"
                    ></span>
                    {{ measuringPoint.to }}
                </ng-container>

                <ng-container *ngSwitchCase="'locationCode'">
                    {{ measuringPoint?.location?.code }}
                </ng-container>

                <ng-container *ngSwitchCase="'ownerId'">
                    <app-navigator
                        [enabled]="organizationNavigation"
                        [organizationId]="measuringPoint.ownerId"
                    ></app-navigator>
                </ng-container>

                <ng-container *ngSwitchCase="'device'">
                    <div
                        class="flex align-center"
                        *ngIf="measuringPoint.device"
                    >
                        <app-navigator
                            [device]="measuringPoint.device"
                            [enabled]="deviceNavigation"
                        ></app-navigator>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'commands'">
                    <div class="flex">
                        <ng-container *ngFor="let command of relevantCommands; trackBy: commandTrackByFn">
                            <app-icon-button
                                *ngIf="command.rowValidFunc(measuringPoint)"
                                [disabled]="command.disabledFunc()"
                                [icon]="command.icon"
                                [text]="command.text"
                                (click)="command.click(measuringPoint)"
                            >
                            </app-icon-button>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{ measuringPoint[column.field] }}
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>
