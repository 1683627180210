import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus } from "@azure/msal-browser";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "@ramudden/core/constants";
import { UserApi } from "@ramudden/data-access/resource/user.api";
import { BackendRights } from "@ramudden/models/backend-rights";
import { NavMenuLink } from "@ramudden/models/nav-menu-link";
import { Rights } from "@ramudden/models/rights";
import {
    ConfigurationService,
    LocalStorageService,
    MapDataService,
    SessionStorageService,
    WebsiteService,
} from "@ramudden/services";
import { Observable, firstValueFrom } from "rxjs";
import { filter, first } from "rxjs/operators";
import { RouteType } from "../app-routing.module";
import { GlobalEventsService } from "./global-events.service";
import { MapDetail } from "./map-detail.service";

const assignmentsRoute = "/assignments";

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    constructor(
        private router: Router,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private readonly translateService: TranslateService,
        private readonly sessionStorageService: SessionStorageService,
        private readonly localStorageService: LocalStorageService,
        private readonly userApi: UserApi,
        private readonly mapDataService: MapDataService,
        private readonly configurationService: ConfigurationService,
        private readonly websiteService: WebsiteService,
        private readonly globalEventsService: GlobalEventsService,
    ) {}
    private clearData() {
        this.mapDataService.clear();
        this.sessionStorageService.clear();
    }
    //#region MSAL

    init() {
        // this.msalService.initialize();
        this.msalService.handleRedirectObservable().subscribe();

        this.msalBroadcastService.msalSubject$
            .pipe(
                first(
                    (value) =>
                        value.eventType === EventType.LOGIN_SUCCESS ||
                        value.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                        value.eventType === EventType.SSO_SILENT_SUCCESS,
                ),
            )
            .subscribe((result: EventMessage) => {
                // console.log("token succeeded!");
                this.globalEventsService.setIsAuthenticated(true);
                this.authorize();
            });
        this.msalBroadcastService.msalSubject$
            .pipe(first((value) => value.eventType === EventType.ACQUIRE_TOKEN_FAILURE))
            .subscribe((result: EventMessage) => {
                console.log("ACQUIRE_TOKEN_FAILURE!");
                // How did we end up here?
                // My guess: user is logged in on signcoserv.be
                // Goes to dev/test.signcoserv.be
                // Press login
                // Service side authenticated, but local refresh token was expired
                // So, local logout is required - this will clear the local refresh token
                this.msalService.logoutRedirect({
                    onRedirectNavigate: (url) => {
                        // Return false if you would like to stop navigation after local logout
                        return false;
                    },
                });
            });
        this.msalBroadcastService.msalSubject$
            .pipe(first((value) => value.eventType === EventType.SSO_SILENT_FAILURE))
            .subscribe((result: EventMessage) => {
                if (!this.globalEventsService.getIsAuthenticated()) {
                    this.globalEventsService.setIsAuthenticated(false);
                }
            });
        this.msalService
            .ssoSilent({
                scopes: [this.configurationService.configuration.azureAuthenticationScope, "openid"],
                extraQueryParameters: { environment: this.websiteService.getB2cEnvironment() },
            })
            .subscribe({
                next: (result: AuthenticationResult) => {},
                error: (error) => {
                    // console.log(error);
                    // uncomment if we automatically want to navigate to login page if not authenticated yet
                    // this.login();
                },
            });
    }

    initialize(): void {
        this.msalService.handleRedirectObservable().subscribe();
        this.msalService.initialize().subscribe(() => {
            this.msalBroadcastService.msalSubject$
                .pipe(
                    first(
                        (value) =>
                            value.eventType === EventType.LOGIN_SUCCESS ||
                            value.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                            value.eventType === EventType.SSO_SILENT_SUCCESS,
                    ),
                )
                .subscribe((result: EventMessage) => {
                    // console.log("token succeeded!");
                    this.globalEventsService.setIsAuthenticated(true);
                    this.authorize();
                });
            this.msalBroadcastService.msalSubject$
                .pipe(first((value) => value.eventType === EventType.ACQUIRE_TOKEN_FAILURE))
                .subscribe((result: EventMessage) => {
                    console.log("ACQUIRE_TOKEN_FAILURE!");
                    // How did we end up here?
                    // My guess: user is logged in on signcoserv.be
                    // Goes to dev/test.signcoserv.be
                    // Press login
                    // Service side authenticated, but local refresh token was expired
                    // So, local logout is required - this will clear the local refresh token
                    this.msalService.logoutRedirect({
                        onRedirectNavigate: (url) => {
                            // Return false if you would like to stop navigation after local logout
                            return false;
                        },
                    });
                });
            this.msalBroadcastService.msalSubject$
                .pipe(first((value) => value.eventType === EventType.SSO_SILENT_FAILURE))
                .subscribe((result: EventMessage) => {
                    if (!this.globalEventsService.getIsAuthenticated()) {
                        this.globalEventsService.setIsAuthenticated(false);
                    }
                });
            this.msalService
                .ssoSilent({
                    scopes: [this.configurationService.configuration.azureAuthenticationScope, "openid"],
                    extraQueryParameters: { environment: this.websiteService.getB2cEnvironment() },
                })
                .subscribe({
                    next: (result: AuthenticationResult) => {},
                    error: (error) => {
                        // console.log(error);
                        // uncomment if we automatically want to navigate to login page if not authenticated yet
                        // this.login();
                    },
                });
        });
    }

    authorize() {
        return firstValueFrom(this.userApi.getSelf$())
            .then((user) => {
                if (user) {
                    this.globalEventsService.setAuthorizationInfo(user);
                    this.redirectToCallbackRoute();
                }
            })
            .catch((error) => {
                const message = JSON.stringify(error);
                console.error(`An error occured while trying to authorize: ${message}`);
                this.signoutSilently();
            });
    }

    acquireTokenSilent$(): Observable<AuthenticationResult> {
        // Sets account as active account or first account
        let account: AccountInfo;
        if (this.msalService.instance.getActiveAccount()) {
            this.msalService.getLogger().verbose("Interceptor - active account selected");
            account = this.msalService.instance.getActiveAccount();
        } else {
            this.msalService.getLogger().verbose("Interceptor - no active account, fallback to first account");
            account = this.msalService.instance.getAllAccounts()[0];
        }
        return this.msalService.acquireTokenSilent({
            scopes: [this.configurationService.configuration.azureAuthenticationScope, "openid"],
            account,
            extraQueryParameters: { environment: this.websiteService.getB2cEnvironment() },
        });
    }

    login() {
        let language = this.translateService.currentLang;
        if (!language) {
            language = "en";
        }

        const loginRequest = {
            scopes: [this.configurationService.configuration.azureAuthenticationScope, "openid"],
            extraQueryParameters: { ui_locales: language, environment: this.websiteService.getB2cEnvironment() },
        };
        this.msalBroadcastService.inProgress$
            .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
            .subscribe(() => {
                this.msalService.loginRedirect(loginRequest);
            });
    }

    signoutSilently() {
        this.logoutMsal();
        sessionStorage.removeItem(Constants.callbackRoute);
        this.clearData();
        // this.currentUser = null;
        // this.globalEventsService.setIsAuthenticated(false);
        // this.globalEventsService.setAuthorizationInfo(null);
        // this.router.navigate(["/"]);
    }

    private logoutMsal() {
        const logoutRequest = {
            account: this.msalService.instance.getActiveAccount(),
        };
        this.msalService.logoutRedirect(logoutRequest);
    }

    private redirectToCallbackRoute() {
        // retrieve last route from local storage
        let callbackRoute = this.localStorageService.getItem(Constants.callbackRoute);
        this.localStorageService.removeItem(Constants.callbackRoute);
        let queryParameters: any = null;
        if (callbackRoute) {
            // Handle query params
            if (callbackRoute.contains("?")) {
                const splitCallback = callbackRoute.split("?");
                callbackRoute = splitCallback[0];

                // Query params example: id=5&x=y
                queryParameters = {};

                const queryParamPairs = splitCallback[1].split("&");
                for (const queryParam of queryParamPairs) {
                    const queryParamSplit = queryParam.split("=");
                    const queryParamKey = queryParamSplit[0];
                    const queryParamValue = queryParamSplit[1];

                    queryParameters[queryParamKey] = queryParamValue;
                }
            }

            // Redirect
            if (callbackRoute !== "/callback") {
                if (queryParameters) {
                    this.router.navigate([callbackRoute], { queryParams: queryParameters });
                } else {
                    this.router.navigate([callbackRoute]);
                }
            } else {
                this.router.navigate(["/"]);
            }
        }
    }
    //#endregion

    static getRightsPerRoute(routeType: RouteType): BackendRights[] {
        switch (routeType) {
            case RouteType.Administration:
                return [BackendRights.ViewProject, BackendRights.ViewOrganization];

            case RouteType.Assignments:
                return [BackendRights.ViewAssignment];

            case RouteType.Configuration:
                return [BackendRights.EditWorker, BackendRights.EditSafetyQuestion];

            case RouteType.Data:
                return [BackendRights.ViewVehicleDayOverviews];

            case RouteType.Devices:
                return [BackendRights.ViewDevice];

            case RouteType.History:
                return [BackendRights.ViewUpload];

            case RouteType.Link:
                return [BackendRights.ViewDevice, BackendRights.ViewDeviceLink];

            case RouteType.Locations:
                return [BackendRights.ViewMeasuringPoint];

            case RouteType.Groups:
                return [BackendRights.ViewGroup];

            case RouteType.Planning:
                return [BackendRights.ViewPlanning];

            case RouteType.Reports:
                return [BackendRights.ViewReport];

            case RouteType.Scenarios:
                return [BackendRights.ViewScenario, BackendRights.ViewDeviceDisplayEvent];

            case RouteType.Worklist:
                return [BackendRights.ViewWorklistItems];

            default:
                return []; // by default route will be available to anyone
        }
    }

    getNavigationLinks(rights: Rights): NavMenuLink[] {
        const links = [];

        const isVisible = (routeType: RouteType) => {
            const requiredRights = AuthenticationService.getRightsPerRoute(routeType);
            return requiredRights.some((x) => rights.hasBackendRight(x));
        };

        if (isVisible(RouteType.Locations)) {
            links.push({
                title: "location-map.title",
                route: "/locations",
                icon: "map-icons",
                screenName: "LocationMapScreen",
            });
        }
        if (isVisible(RouteType.Groups)) {
            links.push({
                title: "groups.title",
                route: "/groups",
                icon: "groups",
                screenName: "GroupsScreen",
                mapDetail: MapDetail.MeasuringPointGroups,
            });
        }
        if (isVisible(RouteType.Assignments)) {
            links.push({
                title: "assignment.title",
                route: assignmentsRoute,
                icon: "assignments",
                screenName: "AssignmentsScreen",
                mapDetail: MapDetail.Assignments,
            });
        }
        if (isVisible(RouteType.Devices)) {
            links.push({
                title: "devices.title",
                route: "/devices",
                icon: "hardware",
                screenName: "DevicesScreen",
                mapDetail: MapDetail.Devices,
            });
        }
        if (isVisible(RouteType.Administration)) {
            links.push({
                title: "administration.title",
                route: "/administration",
                icon: "projects",
                screenName: "AdministrationScreen",
            });
        }
        if (isVisible(RouteType.Data)) {
            links.push({
                title: "data.title",
                route: "/data",
                icon: "data",
                screenName: "DataScreen",
            });
        }
        if (isVisible(RouteType.Reports)) {
            links.push({
                title: "reports.title",
                route: "/reports",
                icon: "reports",
                screenName: "ReportsScreen",
            });
        }
        if (isVisible(RouteType.Scenarios)) {
            links.push({
                title: "scenarios.title",
                route: "/scenarios",
                icon: "calendar",
                screenName: "ScenariosScreen",
            });
        }
        if (isVisible(RouteType.Planning)) {
            links.push({
                title: "assignmentPlanning.title",
                route: "/planning",
                icon: "calendar",
                screenName: "AssignmentPlanningScreen",
            });
        }
        if (isVisible(RouteType.Worklist)) {
            links.push({
                title: "worklists.title",
                route: "/worklists",
                icon: "list",
                screenName: "WorklistsScreen",
            });
        }
        if (isVisible(RouteType.History)) {
            links.push({
                title: "history.title",
                route: "/history",
                icon: "hourglass",
                screenName: "HistoryScreen",
            });
        }
        if (isVisible(RouteType.Configuration)) {
            links.push({
                title: "admin.title",
                route: "/configuration",
                icon: "settings",
                screenName: "ManagementScreen",
            });
        }

        return links;
    }
}
