import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { SubscriptionManager } from "@ramudden/core/utils";
import { RolesApi } from "@ramudden/data-access/resource/roles.api";
import { UserApi } from "@ramudden/data-access/resource/user.api";
import { NavMenuLink } from "@ramudden/models/nav-menu-link";
import { Rights } from "@ramudden/models/rights";
import { AuthorizationInfo, IOrganization, Roles } from "@ramudden/models/user";
import {
    DocumentEventService,
    ImpersonationService,
    MapDataService,
    ProgressService,
    ResizeService,
    UploadFileService,
    ValidationService,
    WebsiteService,
} from "@ramudden/services";
import { ModalService } from "@ramudden/ui";
import { SelectItem } from "primeng/api";
import { DropdownModule } from "primeng/dropdown";
import { firstValueFrom } from "rxjs";
import { filter } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { NotificationBubbleComponent } from "../../../pages/notifications/components/notification-bubble/notification-bubble.component";
import { AuthenticationService } from "../../../services/authentication.service";
import { DomainData, DomainDataService } from "../../../services/domain-data.service";
import { GlobalEventsService } from "../../../services/global-events.service";
import { MapDetailService } from "../../../services/map-detail.service";
import { NavigationService } from "../../../services/navigation.service";
import { PrimeComponentService } from "../../../services/prime-component.service";
import { SharedModule } from "../../../shared/shared.module";

const assignmentsRoute = "/assignments";

@Component({
    selector: "app-page-header",
    standalone: true,
    imports: [
        DropdownModule,
        RouterLink,
        SharedModule,
        SharedModule,
        TranslateModule,
        SharedModule,
        NotificationBubbleComponent,
    ],
    templateUrl: "./page-header.component.html",
    styleUrl: "./page-header.component.scss",
})
export class PageHeaderComponent implements OnInit, OnDestroy {
    @ViewChild("nav", { static: true }) navElementRef: ElementRef<HTMLDivElement>;
    impersonationOrganizations: SelectItem[];
    roles: SelectItem[];
    languages: SelectItem[];
    isAuthenticated: boolean | null = null;
    isAuthorized: boolean;
    availableNavLinks: NavMenuLink[] = [];
    authorizationInfo: AuthorizationInfo;
    rights: Rights;
    private navbar: HTMLDivElement;
    private navDropdown: HTMLLIElement;
    private organizationLinkIndex: number;

    private subscriptionManager = new SubscriptionManager();
    private readonly mapDataKey: string;

    constructor(
        readonly impersonationService: ImpersonationService,
        readonly translate: TranslateService,
        readonly authenticationService: AuthenticationService,
        readonly mapDetailService: MapDetailService,
        readonly router: Router,
        protected readonly websiteService: WebsiteService,
        private readonly validationService: ValidationService,
        private readonly navigationService: NavigationService,
        private readonly uploadFileService: UploadFileService,
        private readonly progressService: ProgressService,
        private readonly primeComponentService: PrimeComponentService,
        private readonly mapDataService: MapDataService,
        private readonly domainDataService: DomainDataService,
        private readonly documentEventService: DocumentEventService,
        private readonly resizeService: ResizeService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly userApi: UserApi,
        private readonly rolesApi: RolesApi,
        private readonly modalService: ModalService,
    ) {
        this.mapDataKey = this.mapDataService.createKey();
    }

    @ViewChild("navbar", { static: true }) set setNavbar(navbar: ElementRef<HTMLDivElement>) {
        this.navbar = navbar ? navbar.nativeElement : null;
    }

    @ViewChild("navdropdown", { static: false }) set setNavDropdown(navDropdown: ElementRef<HTMLLIElement>) {
        this.navDropdown = navDropdown ? navDropdown.nativeElement : null;
    }

    get showMockProgress(): boolean {
        return this.authorizationInfo?.isActualDomainAdministrator && !environment.production;
    }

    get isMobile(): boolean {
        return this.resizeService.isMobile;
    }

    get isDesktop(): boolean {
        return this.resizeService.isDesktop;
    }

    get organization(): IOrganization {
        return this.globalEventsService.getDefaultOrganization();
    }

    get showImpersonationDropdown(): boolean {
        return this.impersonationService.isImpersonating() || this.globalEventsService.hasMultipleOrganizations();
    }

    get showRoleImpersonationDropdown(): boolean {
        return this.globalEventsService.hasAccessToRoleImpersonation();
    }

    get currentLanguage(): string {
        return this.translate.currentLang;
    }

    ngOnInit() {
        this.domainDataService.get(DomainData.Roles, { orderBy: (x) => x.label }).then((roles) => {
            this.roles = roles;
        });

        const loadOrganizations = () => {
            this.mapDataService.subscribeToOrganizations(this.mapDataKey, (allOrganizations) => {
                // Users see their own orgs + orgs that share data - only show own
                // Admins can be anything
                const relevantOrganizations = !this.authorizationInfo?.user
                    ? []
                    : this.authorizationInfo.user
                      ? allOrganizations
                      : this.globalEventsService.getOrganizations();

                this.impersonationOrganizations = this.primeComponentService.createDropdownList(
                    relevantOrganizations,
                    (x) => x,
                    (x) => x.name,
                    false,
                );
            });
        };

        const onAuthorizationInfoSubscription = this.globalEventsService.authorizationInfo$.subscribe(
            (authorizationInfo) => {
                this.authorizationInfo = authorizationInfo;
                if (authorizationInfo) {
                    loadOrganizations();
                    this.updateOrganizationRouterLink();
                } else {
                    this.impersonationService.clear();
                }
            },
        );

        this.subscriptionManager.add("onAuthorizationInfoSubscription", onAuthorizationInfoSubscription);
        const currentRightsSubscription = this.globalEventsService.currentRights$.subscribe((rights: Rights) => {
            this.rights = rights;
            if (this.rights) {
                this.updateNavLinks(this.router.url);
            }
        });
        this.subscriptionManager.add("currentRightsSubscription", currentRightsSubscription);

        this.domainDataService.get(DomainData.Language).then((languages) => {
            this.languages = languages;
        });

        const onNavigationEndSubscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.updateNavLinks(event.urlAfterRedirects);
            });

        this.subscriptionManager.add("onNavigationEndSubscription", onNavigationEndSubscription);
        this.subscriptionManager.add(
            "isAuthenticatedSubscription",
            this.globalEventsService.isAuthenticated$.subscribe((value) => {
                if (value !== null) {
                    this.isAuthenticated = value;
                }
            }),
        );

        this.subscriptionManager.add(
            "isAuthorizedSubscription",
            this.globalEventsService.isAuthorized$.subscribe((value) => {
                if (value !== null) {
                    this.isAuthorized = value;
                }
            }),
        );
        // rights subscription

        this.updateNavLinks(this.router.url);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
        this.mapDataService.unsubscribe(this.mapDataKey);
    }

    private async updateNavLinks(url: string, forcefullyRedirectWhenDeemedRequired = false) {
        const setInitialIfRequired = async () => {
            if (!this.rights || this.rights.backendRights.length == 0) return;

            this.availableNavLinks = this.authenticationService.getNavigationLinks(this.rights);
            // find position of organization link
            this.organizationLinkIndex = this.availableNavLinks.findIndex(
                (x) => x.screenName === "OrganizationsScreen",
            );
            this.updateOrganizationRouterLink();

            if (forcefullyRedirectWhenDeemedRequired) {
                const curNavLink = this.availableNavLinks.filter((x) => url.contains(x.route));
                if (!curNavLink.length) {
                    const firstPossibleRoute = this.availableNavLinks.takeFirstOrDefault();

                    if (firstPossibleRoute) {
                        // Currently in window where we lost rights to, go to first
                        await this.router.navigate([firstPossibleRoute.route]);
                    }
                }
            }
        };

        // about and documentation pages are a special cases, they're placed in the login dropdown menu or they are not present as option at all (documentation)
        if (!url || url === "/about" || url.contains("/documentation")) {
            await setInitialIfRequired();
            return;
        }

        await setInitialIfRequired();
    }

    toggleNavbar() {
        this.navbar.classList.toggle("show");
    }

    toggleDropdown() {
        if (!this.navDropdown) {
            return;
        }

        const documentServiceKey = "closeNavDropdown";

        if (this.navDropdown.classList.contains("open")) {
            this.navDropdown.classList.remove("open");
            this.documentEventService.removeOnClick(documentServiceKey);
        } else {
            this.navDropdown.classList.add("open");
            this.documentEventService.addOnClick(documentServiceKey, this.navDropdown, () => this.toggleDropdown());
        }
    }

    closeMenus() {
        if (this.navbar && this.navbar.classList.contains("show")) this.toggleNavbar();
        if (this.navDropdown && this.navDropdown.classList.contains("open")) this.toggleDropdown();
    }

    setLanguage(language: string) {
        this.domainDataService.loadLanguage(language).subscribe((domainData) => {
            this.domainDataService.domainData = domainData;
            this.translate.use(language);
            // update user api language
            if (this.authorizationInfo?.user) {
                this.userApi.updateSelfLanguage$(language).subscribe();
            }
        });
    }

    mockProgress() {
        this.uploadFileService.mock();
        this.progressService.mock();
    }

    async setImpersonation(organization: IOrganization) {
        const onOk = async () => {
            this.validationService.setContext(null);
            this.impersonationService.organization = organization;
            this.updateOrganizationRouterLink();
            await this.navigationService.toHome();
            window.location.reload();
        };

        this.modalService.confirm("impersonation.changeOrganization", onOk, () => {}, "info", false);
    }

    async setRoleImpersonation(role: Roles) {
        const onOk = async () => {
            this.validationService.setContext(null);
            this.impersonationService.role = role;

            if (role) {
                this.rights = new Rights(await firstValueFrom(this.rolesApi.getRights$(role)));
            } else {
                this.rights = this.globalEventsService.getCurrentRights();
            }
            await this.updateNavLinks(this.router.url, true);
            window.location.reload();
        };

        this.modalService.confirm("impersonation.changeRole", onOk, () => {}, "info", false);
    }

    login() {
        this.authenticationService.login();
    }

    logout() {
        this.authenticationService.signoutSilently();
    }

    isActive(link: NavMenuLink) {
        if (this.router.url.contains("task/") && link.route === assignmentsRoute) {
            // Assignments option active when looking task
            return true;
        } else if (this.router.url.contains("parkingban/") && link.route === assignmentsRoute) {
            // Assignments option active when looking parkingban
            return true;
        } else if (this.router.url.contains("organizations") && link.route === "/map/organizations") {
            return true;
        } else {
            return (
                this.mapDetailService.currentMapDetail === link.mapDetail ||
                this.router.isActive(link.route, {
                    paths: "subset",
                    queryParams: "ignored",
                    fragment: "ignored",
                    matrixParams: "ignored",
                })
            );
        }
    }

    private updateOrganizationRouterLink() {
        // Only update the organization link if it exists
        if (this.organizationLinkIndex > -1) {
            if (this.globalEventsService.hasMultipleOrganizations()) {
                this.setOrganizationLinkForMultipleOrganizations();
                return;
            }

            this.setOrganizationLinkForSingleOrganization();
        }
    }

    private setOrganizationLinkForMultipleOrganizations() {
        this.availableNavLinks[this.organizationLinkIndex].route = "/administration";
        this.availableNavLinks[this.organizationLinkIndex].title = "organizations.title";
    }

    private setOrganizationLinkForSingleOrganization() {
        const organization = this.globalEventsService.getDefaultOrganization();
        if (!organization) {
            this.availableNavLinks[this.organizationLinkIndex].route = "/";
            return;
        }

        this.availableNavLinks[this.organizationLinkIndex].route =
            `administration/organization/${organization.id}/details`;
        this.availableNavLinks[this.organizationLinkIndex].title = "general.organization";

        if (window.location.href.endsWith("/administration")) {
            this.navigationService.toOrganizations();
        }
    }
}
