import { inject, Injectable } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { JsonUtils } from "@ramudden/core/utils";
import { OrganizationApi } from "@ramudden/data-access/resource/organization.api";
import { UserApi } from "@ramudden/data-access/resource/user.api";
import { ProjectWebApi } from "@ramudden/data-access/resource/web";
import { AlertLevel } from "@ramudden/models/alert";
import { MeasuringPointLinkStatus } from "@ramudden/models/map-filter";
import { AnalysisType } from "@ramudden/models/measuring-point";
import { MeasuringPointSearchParameters } from "@ramudden/models/web";
import { firstValueFrom, Subject } from "rxjs";
import { FilterState, FilterStateChangedEventArgs } from "./filter-state";

export enum FilterServiceFields {
    alertLevels = "alertLevels",
    analysisTypes = "types",
    linkStatus = "linkStatus",
    organizations = "organizations",
    projects = "projects",
    projectsForUserId = "projectsForUserId",
    search = "search",
    panAndZoom = "panAndZoom",
}

/**
 * Shared access to the current filter parameters.
 * For now this service is only used in this module, so it is not injected in root.
 *
 * Responsibilities:
 * * Share access to the current filter parameters
 * * Notify when the filter parameters change
 * * Remember the filter state between navigations
 * * Parse filters from QueryParameters and populate the UI
 */
@Injectable({
    providedIn: "root",
})
export class FilterService {
    private previousFilterState = new FilterState(); // Keeps track of the previous value
    public filterState = new FilterState(); // Keeps track of the current value

    private filterStateChangedSubject = new Subject<FilterStateChangedEventArgs>(); // Not using BehaviorSubject because that is already triggered when subscribing
    public onFilterStateChanged$ = this.filterStateChangedSubject.asObservable(); // It is a best practice not to expose the subject directly

    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);

    private readonly userApi = inject(UserApi);
    private readonly organizationApi = inject(OrganizationApi);
    private readonly projectApi = inject(ProjectWebApi);

    notifyFilterStateChanged(relativeUrl: string = undefined, forceZoomAndPan = false) {
        if (relativeUrl) {
            this.router.navigate([relativeUrl], {
                queryParams: this.getFrontEndUrlQueryParams(forceZoomAndPan),
            });
        } else {
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: this.getFrontEndUrlQueryParams(forceZoomAndPan),
            });
        }

        this.filterStateChangedSubject.next(
            new FilterStateChangedEventArgs(
                this.filterState,
                forceZoomAndPan ? forceZoomAndPan : this.shouldZoomToNewFilter(),
            ),
        );
        this.savePreviousFilterState();
    }

    private savePreviousFilterState() {
        this.previousFilterState = JsonUtils.deepClone(this.filterState);
    }

    private shouldZoomToNewFilter(): boolean {
        return (
            (!!this.filterState.organizations.length &&
                !this.filterState.organizations.areContentsEqual(
                    this.previousFilterState?.organizations,
                    (x) => x.id,
                )) ||
            (!!this.filterState.projects.length &&
                !this.filterState.projects.areContentsEqual(this.previousFilterState?.projects, (x) => x.id))
        );
    }

    // Convert our FE filter state in BE search parameters
    getSearchParameters(): MeasuringPointSearchParameters {
        const result = new MeasuringPointSearchParameters();
        if (this.filterState.projects.length) {
            result.projectIds = this.filterState.projects.map((x) => x.id);
        }

        if (this.filterState.types.length) {
            result.analysisTypeIds = this.filterState.types;
        }
        if (this.filterState.alertLevels.length) {
            result.alertLevelIds = this.filterState.alertLevels;
        }

        if (this.filterState.linkStatus === MeasuringPointLinkStatus.Linked) {
            result.hasLinkedDevice = true;
        } else if (this.filterState.linkStatus === MeasuringPointLinkStatus.NotLinked) {
            result.hasLinkedDevice = false; // Note that false has a different meaning than null here
        }

        if (this.filterState.organizations.length) {
            result.ownerIds = this.filterState.organizations.map((x) => x.id);
        }

        if (this.filterState.searchTerm) {
            result.search = this.filterState.searchTerm;
        }

        if (this.filterState.projectsForUserId) {
            result.projectsForUserId = this.filterState.projectsForUserId.id;
        }

        return result;
    }

    async initializeFilterStateFromQueryParams(params: Params): Promise<boolean> {
        // When navigating using the browser back or forward
        // it's possible queryparams get loaded on-top of existing ones
        // So sanity check before adding to filterState
        const getValues = (valueInParams: any) => {
            return (valueInParams + "").split(",");
        };

        const organizationsParam = params[FilterServiceFields.organizations];
        if (organizationsParam) {
            const organizationIds = getValues(organizationsParam).map((x) => +x);

            for (const organizationId of organizationIds) {
                if (this.filterState.organizations.find((x) => x.id === organizationId)) continue;

                const organization = await firstValueFrom(this.organizationApi.get$(organizationId));
                this.filterState.organizations.push(organization);
            }
        }

        const alertLevelsParam = params[FilterServiceFields.alertLevels];
        if (alertLevelsParam) {
            const alertLevels = getValues(alertLevelsParam).map((x) => <AlertLevel>x);

            for (const alertLevel of alertLevels) {
                if (this.filterState.alertLevels.contains(alertLevel)) continue;

                this.filterState.alertLevels.push(alertLevel);
            }
        }

        const projectsParams = params[FilterServiceFields.projects];
        if (projectsParams) {
            const projectIds = getValues(projectsParams).map((x) => +x);

            for (const projectId of projectIds) {
                if (this.filterState.projects.find((x) => x.id === projectId)) continue;

                const project = await this.projectApi.get(projectId);
                this.filterState.projects.push(project);
            }
        }

        const analysisTypeParams = params[FilterServiceFields.analysisTypes];
        if (analysisTypeParams) {
            const analysisTypes = getValues(analysisTypeParams).map((x) => <AnalysisType>x);

            for (const analysisType of analysisTypes) {
                if (this.filterState.types.contains(analysisType)) continue;

                this.filterState.types.push(analysisType);
            }
        }

        const hasLinkedDeviceParam = params[FilterServiceFields.linkStatus];
        if (hasLinkedDeviceParam) {
            this.filterState.linkStatus =
                hasLinkedDeviceParam === "true" ? MeasuringPointLinkStatus.Linked : MeasuringPointLinkStatus.NotLinked;
        }

        const searchParam = params[FilterServiceFields.search];
        if (searchParam) {
            this.filterState.searchTerm = searchParam;
        }

        const projectsForUserId = params[FilterServiceFields.projectsForUserId];
        if (projectsForUserId === "me") {
            this.filterState.projectsForUserId = await firstValueFrom(this.userApi.getSelf$());
        }

        const zoomAndPanParameter = params[FilterServiceFields.panAndZoom];
        const shouldZoom = zoomAndPanParameter ? zoomAndPanParameter === "true" : this.shouldZoomToNewFilter();
        this.savePreviousFilterState();
        return shouldZoom;
    }

    private getFrontEndUrlQueryParams(forceZoomAndPan: boolean): Params {
        const parameters = this.getSearchParameters();

        const params = {};
        if (parameters.alertLevelIds) {
            params[FilterServiceFields.alertLevels] = parameters.alertLevelIds.join(",");
        }
        if (parameters.ownerIds) {
            params[FilterServiceFields.organizations] = parameters.ownerIds.join(",");
        }
        if (parameters.projectIds) {
            params[FilterServiceFields.projects] = parameters.projectIds.join(",");
        }
        if (parameters.analysisTypeIds) {
            params[FilterServiceFields.analysisTypes] = parameters.analysisTypeIds.join(",");
        }
        // if "filled in" so either true or false, no binary checks
        if (parameters.hasLinkedDevice === true || parameters.hasLinkedDevice === false) {
            params[FilterServiceFields.linkStatus] = parameters.hasLinkedDevice === true;
        }
        if (parameters.search) {
            params[FilterServiceFields.search] = parameters.search;
        }
        if (parameters.projectsForUserId) {
            params[FilterServiceFields.projectsForUserId] = "me";
        }
        if (forceZoomAndPan) {
            params[FilterServiceFields.panAndZoom] = true;
        }

        return params;
    }
}
