import { Injectable } from "@angular/core";
import { DeviceLinkCreator, DeviceLinkUpdater, IDeviceLink, IDeviceLinkConflicts } from "@ramudden/models/device-link";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class DeviceLinkApi extends ApiService<IDeviceLink, DeviceLinkCreator, DeviceLinkUpdater> {
    override getRoute(): string {
        return "DeviceLinks";
    }

    preview$(model: DeviceLinkCreator | DeviceLinkUpdater): Observable<IDeviceLinkConflicts> {
        if (model instanceof DeviceLinkUpdater) {
            const updateUrl = `${super.getUrl()}/${model.id}/preview`;
            return this.http.put<IDeviceLinkConflicts>(updateUrl, model);
        }

        const url = `${super.getUrl()}/Preview`;
        return this.http.post<IDeviceLinkConflicts>(url, model);
    }

    getDeviceLinksByMeasuringPointId$(measuringPointId: number): Observable<any> {
        const url = `${super.getUrl()}/`;
        return this.http.get<IDeviceLink[]>(url, { params: { measuringPointId } });
    }
}
