import { inject, Injectable } from "@angular/core";
import { Constants } from "@ramudden/core/constants";
import { IOrganization, Roles } from "@ramudden/models/user";
import { LocalStorageService } from "./storage.service";

@Injectable({
    providedIn: "root",
})
export class ImpersonationService {
    _organization: IOrganization;
    get organization(): IOrganization {
        if (!this._organization) {
            if (this.localStorage.getItem(Constants.organizationImpersonationStorageKey)) {
                this._organization = JSON.parse(
                    this.localStorage.getItem(Constants.organizationImpersonationStorageKey),
                );
            }
        }
        return this._organization;
    }
    set organization(organization: IOrganization) {
        if (organization === this._organization) return;

        if (organization) {
            this.localStorage.setItem(Constants.organizationImpersonationStorageKey, JSON.stringify(organization));
        } else {
            this.localStorage.removeItem(Constants.organizationImpersonationStorageKey);
        }
        this._organization = organization;
    }

    private _role: Roles;
    get role(): Roles {
        if (this.localStorage.getItem(Constants.roleImpersonationStorageKey)) {
            this._role = this.localStorage.getItem(Constants.roleImpersonationStorageKey) as Roles;
        }
        return this._role;
    }

    set role(role: Roles) {
        if (role === this._role) return;

        if (role) {
            this.localStorage.setItem(Constants.roleImpersonationStorageKey, role.toString());
        } else {
            this.localStorage.removeItem(Constants.roleImpersonationStorageKey);
        }
        this._role = role;
    }

    private readonly localStorage = inject(LocalStorageService);

    isImpersonating(): boolean {
        return !!this.organization || !!this.role;
    }

    clear() {
        this._organization = null;
        this._role = null;
    }

    //#endregion
}
