import { IAddress } from "../location";
import { AnalysisType } from "../measuring-point";
import { IProjectNavigator } from "../project";

export interface ILocationSummary {
    id: number;
    ownerId: number;
    lat: number;
    lng: number;
    code: string;
    iconStateId: number;
    address: IAddress;
    analysisTypes: AnalysisType[];
    projects: IProjectNavigator[];
}

export interface ILocationWithDevicesSummary extends ILocationSummary {
    alarmSeverityId: string;
}

export interface ILocationWithAssignmentsSummary extends ILocationSummary {
    assignmentIds: number[];
}
