import { ExternalInputType } from "./external-input-configuration";
import { LightAction } from "./lightAction";
import { IMeasuringPointStatusData } from "./measuring-point-status";
import { ILightMode } from "./mode-configuration";
import { IScenarioNavigator } from "./scenario";
import { ITotemDisplayConfiguration } from "./totem-display-configuration";
import { VehicleCategory } from "./vehicle";

export interface IHistoricalData {
    dataSets: IVehicleHistoryDataSet[];
}

export interface IVehicleHistoryDataSet {
    option: PinnedDataOption;
    dataSetType: DataSetType;
    vehicleCategory: VehicleCategory;
    history: IVehicleHistoryDataPoint[];
}

export interface IVehicleHistoryDataPoint {
    timestamp: Date;
    value?: number;
}

export interface ILiveData {
    lastValuesGroups: ILiveVehiclesGroupPerOption[];
    from?: Date;
    until?: Date;
    lastUpdate?: Date;
    pinnedData: IPinnedData;
    currentStatus: IMeasuringPointStatusData;
    linkedScenarios: IScenarioNavigator[];
}

export interface ILiveVehiclesGroupPerOption {
    option: PinnedDataOption;
    lastValues: ILiveVehiclesLastValue[];
}

export interface ILiveVehiclesLastValue {
    value?: number;
    dataSetType: DataSetType;
}

export enum LiveDataRangeOption {
    Today = "today",
    Hour = "hour",
    Minutes15 = "minutes15",
    Minutes5 = "minutes5",
}

export interface IPinnedData {
    externalInput: IExternalInputPinnedData;
    light: ILightPinnedData;
    totemDisplay: ITotemDisplayConfiguration;
}

// used for configuration of chart
export enum PinnedDataOption {
    Speed = "speed",
    Distance = "distance",
    Intensity = "intensity",
    ParkingOccupancy = "parkingOccupancy",
}

// used for configuration of chart
export enum DataSetType {
    SpeedV50 = "v50",
    SpeedV85 = "v85",
    SpeedV95 = "v95",
    DistanceA05 = "a05",
    DistanceA15 = "a15",
    DistanceA50 = "a50",
    IntensityLight = "intensityLight",
    IntensityMedium = "intensityMedium",
    IntensityHeavy = "intensityHeavy",
    IntensityBikes = "intensityBikes",
    IntensityMopeds = "intensityMopeds",
    IntensityPedestrians = "intensityPedestrians",
    ParkingOccupancyOccupiedPlaces = "parkingOccupancyOccupiedPlaces",
    ParkingOccupancyAvailablePlaces = "parkingOccupancyAvailablePlaces",
    ParkingOccupancyTotalCapacity = "parkingOccupancyTotalCapacity",
    FloatingCarSpeed = "floatingCarSpeed",
}

export interface IExternalInputPinnedData {
    type: ExternalInputType;
    inputs: InputData[];
}

export interface InputData {
    id: string;
    description: string;
    value?: boolean;
    trueIconUrl?: string;
    trueIconPreview?: string;
    falseIconUrl?: string;
    falseIconPreview?: string;
}

export interface ILightPinnedData {
    currentAction: LightAction;
    iconUrl?: string;
    modes: ILightMode[];
}

export interface IPinnedDataConfigurationPerAnalysisType {
    supportedOptions: IPinnedDataOptionDataSetTypes[];
    defaultLiveDataRangeOption?: LiveDataRangeOption;
}

export interface IPinnedDataOptionDataSetTypes {
    option: PinnedDataOption;
    availableDataSetTypes: DataSetType[];
}

export interface ICountingZoneStatus {
    vehicles: string[];
    removedVehicles: string[];
    accessStatus?: CountingZoneAccessStatus;
}

export enum CountingZoneAccessStatus {
    Closed = "closed",
    OpenForward = "openForward",
    OpenReverse = "openReverse",
}
