import { IDeviceNavigator } from "../device";
import { IGroupMeasuringPoint } from "../group";
import { AnalysisType, IMeasuringPointNavigator } from "../measuring-point";
import { ILocationSummary } from "./location-summary";

export interface IMeasuringPointSummary extends IMeasuringPointNavigator {
    description: string;
    from: string;
    to: string;
    ownerId: number;
    drivingLaneId: number;
    analysisTypeId: AnalysisType;

    // location: ILocation;
    groups: IGroupMeasuringPoint[];
    fromTo: string;
    googlePlaceId?: string;

    device: IDeviceNavigator;
    deviceLinkId: number;
    lastDeviceLinkValidFrom?: Date;
    lastDeviceLinkValidUntil?: Date;

    location: ILocationSummary;
    heading: number;

    // used by UI
    includeForwardDirection?: boolean;
    includeReverseDirection?: boolean;
    includeSum?: boolean;
    sortOrder?: number;
}
