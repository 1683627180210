<p-table
    #tableRef
    [columns]="relevantColumns"
    [loading]="loading"
    [pageLinks]="pageLinks"
    [paginator]="paginator"
    [rows]="internalRowCount"
    [scrollable]="scrollable"
    [scrollHeight]="scrollHeight"
    [selectionMode]="selectionMode"
    [styleClass]="styleClass"
    [totalRecords]="data?.length"
    [value]="data"
    (onPage)="onPage($event)"
    (onRowReorder)="reorder()"
    (onRowSelect)="onRowSelect($event)"
    (onRowUnselect)="onRowDeselect($event)"
    dataKey="measuringPoint.id"
    sortField="sortOrder"
    sortOrder="1"
>
    <ng-template pTemplate="emptymessage">
        <tr *ngIf="!loading && emptyMessage">
            <td
                class="m-table-empty-message"
                [attr.colspan]="relevantColumns.length"
            >
                {{ "table.empty" | translate }}
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="total"> {{ "table.total" | translate }}: {{ total }}</ng-container>
    </ng-template>

    <ng-template
        *ngIf="footer && !paginator"
        pTemplate="summary"
    >
        <ng-container *ngIf="total"> {{ "table.total" | translate }}: {{ total }}</ng-container>
    </ng-template>

    <ng-template
        *ngIf="headers"
        pTemplate="header"
    >
        <tr>
            <th
                *ngFor="let column of relevantColumns; trackBy: columnTrackByFn; let i = index"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field"
                [pSortableColumn]="column.field"
                [pSortableColumnDisabled]="!sortable || !column.sortable"
            >
                <div class="m-column-header">
                    <span
                        class="m-column-title"
                        *ngSwitchCase="'includeForwardDirection'"
                        style="text-align: center"
                    >
                        <app-svg-icon
                            icon="arrow-right"
                            tooltip="{{ 'measuringPoints.primaryDirection' | translate }}"
                        >
                        </app-svg-icon>

                        <input
                            [(ngModel)]="isAllForwardSelected"
                            [disabled]="!data?.length || !canEdit"
                            (change)="bulkSelectChanged('forward')"
                            (click)="stopEvent($event)"
                            type="checkbox"
                        />
                    </span>

                    <span
                        class="m-column-title"
                        *ngSwitchCase="'includeReverseDirection'"
                        style="text-align: center"
                    >
                        <app-svg-icon
                            icon="arrow-left"
                            tooltip="{{ 'measuringPoints.reverseDirection' | translate }}"
                        >
                        </app-svg-icon>

                        <input
                            [(ngModel)]="isAllReverseSelected"
                            [disabled]="!data?.length || !canEdit"
                            (change)="bulkSelectChanged('reverse')"
                            (click)="stopEvent($event)"
                            type="checkbox"
                        />
                    </span>

                    <span
                        class="m-column-title"
                        *ngSwitchCase="'includeSum'"
                        style="text-align: center"
                    >
                        <app-svg-icon
                            icon="arrow-sum"
                            tooltip="{{ 'measuringPoints.sumDirection' | translate }}"
                        >
                        </app-svg-icon>

                        <input
                            [(ngModel)]="isAllSumSelected"
                            [disabled]="!data?.length || !canEdit"
                            (change)="bulkSelectChanged('sum')"
                            (click)="stopEvent($event)"
                            type="checkbox"
                        />
                    </span>

                    <ng-container *ngSwitchDefault>
                        <div class="m-column-header-wrapper">
                            <span class="m-column-title">
                                {{ column.header | translate }}
                            </span>

                            <span
                                class="m-column-title"
                                *ngIf="i == 0"
                                style="text-align: center"
                            >
                                <a (click)="alphabeticalSorting()">
                                    <app-svg-icon
                                        icon="a-z-sortIcon"
                                        tooltip="{{ 'measuringPoints.a-zSorting' | translate }}"
                                    >
                                    </app-svg-icon>
                                </a>
                            </span>

                            <p-sortIcon
                                *ngIf="sortable && column.sortable"
                                [field]="column.field"
                            ></p-sortIcon>

                            <app-table-filter
                                class="m-column-filter"
                                *ngIf="filter"
                                [column]="column"
                                [table]="table"
                            >
                            </app-table-filter>
                        </div>

                        <app-table-filter-preview [column]="column"></app-table-filter-preview>
                    </ng-container>
                </div>
            </th>
        </tr>
    </ng-template>

    <ng-template
        let-groupMeasuringPoint
        let-index="rowIndex"
        pTemplate="body"
    >
        <tr
            [pReorderableRow]="index"
            [pSelectableRow]="groupMeasuringPoint"
        >
            <td
                *ngFor="let column of relevantColumns; trackBy: columnTrackByFn"
                [ngStyle]="column.ngStyle"
                [ngSwitch]="column.field"
            >
                <ng-container *ngSwitchCase="'reorder'">
                    <div
                        class="flex justify-center"
                        style="width: 100%"
                    >
                        @if (canEdit) {
                            <i
                                class="fa fa-bars"
                                pReorderableRowHandle
                            ></i>
                        } @else {
                            <i class="fa fa-bars"></i>
                        }
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'sortOrder'">
                    {{ groupMeasuringPoint.sortOrder }}
                </ng-container>
                <ng-container *ngSwitchCase="'includeForwardDirection'">
                    <input
                        class="m-input-checkbox"
                        [(ngModel)]="groupMeasuringPoint.includeForwardDirection"
                        [disabled]="!canEdit"
                        (change)="updateInGroup(groupMeasuringPoint, 'forward')"
                        type="checkbox"
                    />
                </ng-container>

                <ng-container *ngSwitchCase="'includeReverseDirection'">
                    <input
                        class="m-input-checkbox"
                        [(ngModel)]="groupMeasuringPoint.includeReverseDirection"
                        [disabled]="!canEdit"
                        (change)="updateInGroup(groupMeasuringPoint, 'reverse')"
                        type="checkbox"
                    />
                </ng-container>

                <ng-container *ngSwitchCase="'includeSum'">
                    <input
                        class="m-input-checkbox"
                        [(ngModel)]="groupMeasuringPoint.includeSum"
                        [disabled]="!canEdit"
                        (change)="updateInGroup(groupMeasuringPoint, 'sum')"
                        type="checkbox"
                    />
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.analysisTypeId'">
                    <span class="m-circle m-circle-grey center">
                        <app-svg-icon
                            [icon]="groupMeasuringPoint.measuringPoint.analysisTypeId"
                            [tooltip]="
                                getMeasuringPointAnalysisTypeTranslation(
                                    groupMeasuringPoint.measuringPoint.analysisTypeId
                                )
                            "
                        >
                        </app-svg-icon>
                    </span>
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.location.code'">
                    {{ groupMeasuringPoint.measuringPoint.location.code }}
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.code'">
                    {{ groupMeasuringPoint.measuringPoint.code }}
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.description'">
                    {{ groupMeasuringPoint.measuringPoint.description }}
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.fromTo'">
                    {{ groupMeasuringPoint.measuringPoint.from }}
                    <span
                        class="m-arrow-right"
                        *ngIf="groupMeasuringPoint.measuringPoint.from || groupMeasuringPoint.measuringPoint.to"
                    ></span>
                    {{ groupMeasuringPoint.measuringPoint.to }}
                </ng-container>

                <ng-container *ngSwitchCase="'measuringPoint.location.owner'">
                    <app-navigator
                        [enabled]="navigation"
                        [organizationId]="groupMeasuringPoint.measuringPoint.ownerId"
                    ></app-navigator>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    {{ groupMeasuringPoint[column.field] }}
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>
